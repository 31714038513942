import React from "react";
import { Route } from "react-router-dom";
import { ConnectedSwitch } from "./reactRouterConnected";
import Loadable from "react-loadable";
import Page from "./../components/LayoutComponents/Page";
import NotFoundPage from "./../../modules/error-pages/NotFoundPage";
import LoginPage from "../../modules/authentication/LoginPage";

export const BULK_PAYCODE_ROUTE = "bulk-paycodes";
export const DASHBOARD_ROUTE = "/dashboard";
export const SETTINGS_ROUTE = "/settings";

const loadable = (loader) =>
  Loadable({
    loader,
    delay: 300,
    loading: () => null,
    render(loaded, props) {
      const Component = loaded.default;
      return <Component {...props} />;
    },
  });

const loadableRoutes = {
  "/login": {
    component: loadable(() => import("./../../modules/authentication/LoginPage")),
  },
  "/verify-email/:id": {
    component: loadable(() => import("../../modules/authentication/VerifyEmailPage")),
  },
  "/verify-mobile": {
    component: loadable(() => import("../../modules/authentication/VerifyMobilePage")),
  },
  "/verify-otp": {
    component: loadable(() => import("../../modules/authentication/VerifyOtpPage")),
  },
  "/mfa-setup-complete": {
    component: loadable(() => import("../../modules/authentication/MfaSetupCompletePage")),
  },
  "/update-mobile": {
    component: loadable(() => import("../../modules/authentication/UpdateMobilePage")),
  },
  "/activate-invite": {
    component: loadable(() => import("../../modules/authentication/ActivateInvitePage")),
  },
  "/create-account": {
    component: loadable(() => import("./../../modules/authentication/CreateAccountPage")),
  },

  "/on-boarding/complete": {
    component: loadable(() => import("./../../modules/authentication/SetPasswordPage")),
  },

  "/forgot-password": {
    component: loadable(() => import("./../../modules/authentication/ForgotPasswordPage")),
  },

  "/reset-password/complete": {
    component: loadable(() => import("./../../modules/authentication/ResetPasswordPage")),
  },

  "/activate-business": {
    component: loadable(() => import("../../modules/onboarding/activateBusinessPage")),
  },

  "/onboard": {
    component: loadable(() => import("../../modules/onboarding/onboardPage")),
  },

  "/mfa-setup": {
    component: loadable(() => import("./../../modules/authentication/MfaSetupPage")),
  },

  "/dashboard": {
    component: loadable(() => import("./../../modules/dashboard")),
  },

  "/transactions": {
    component: loadable(() => import("./../../modules/transactions/TransactionListPage")),
  },
  "/transactions-details": {
    component: loadable(() => import("../../modules/transactions/TransactionDetailsPage")),
  },
  "/transactions/:transactionReference/refund": {
    component: loadable(
      () => import("../../modules/transactions/TransactionDetailsPage/RefundPage")
    ),
  },

  "/reserved-accounts": {
    component: loadable(() => import("./../../modules/reserved-accounts/ReservedAccountsPage")),
  },

  "/reserved-accounts/create": {
    component: loadable(
      () => import("./../../modules/reserved-accounts/CreateReservedAccountPage")
    ),
  },

  "/reserved-accounts/:accountReference": {
    component: loadable(
      () => import("./../../modules/reserved-accounts/ReservedAccountDetailsPage")
    ),
  },
  "/sub-accounts": {
    component: loadable(() => import("./../../modules/sub-accounts/SubAccountsPage")),
  },
  "/offline-products": {
    component: loadable(
      () => import("./../../modules/offline-payments/OfflineMerchanProductsPage")
    ),
  },
  "/offline-products/create": {
    component: loadable(
      () =>
        import(
          "./../../modules/offline-payments/OfflineMerchanProductsPage/CreateOfflineMerchantProductPage"
        )
    ),
  },
  "/offline-products/edit/:productCode": {
    component: loadable(
      () =>
        import(
          "./../../modules/offline-payments/OfflineMerchanProductsPage/CreateOfflineMerchantProductPage"
        )
    ),
  },
  "/offline-products/:productCode": {
    component: loadable(
      () =>
        import(
          "./../../modules/offline-payments/OfflineMerchanProductsPage/OfflineMerchantProductDetailsPage"
        )
    ),
  },

  // paycodes
  "/paycodes/create": {
    component: loadable(() => import("./../../modules/paycodes/CreatePaycodePage")),
  },
  "/paycodes": {
    component: loadable(() => import("./../../modules/paycodes")),
    exact: false,
  },
  BULK_PAYCODE_ROUTE: {
    component: loadable(() => import("./../../modules/paycodes")),
    exact: false,
  },
  "/approval-requests": {
    component: loadable(() => import("./../../modules/paycodes")),
    exact: false,
  },
  // refunds
  "/refunds": {
    component: loadable(() => import("./../../modules/refund")),
  },
  "/refunds/upload-history": {
    component: loadable(
      () => import("./../../modules/refund/RefundUploadHistory/RefundUploadHistory")
    ),
  },
  "/rejected-payments": {
    component: loadable(() => import("./../../modules/refund/RejectedPaymentsPageManagement")),
  },
  "/rejected-payments/bulk-update": {
    component: loadable(
      () =>
        import("../../modules/refund/RejectedPaymentsPageManagement/BulkUpdateRejectedPaymentsPage")
    ),
    exact: false,
  },
  "/rejected-payments/rejected-payment-details/:rejectedPaymentRef": {
    component: loadable(
      () =>
        import("../../modules/refund/RejectedPaymentsPageManagement/RejectedPaymentsDetailsPage")
    ),
    exact: false,
  },
  "/refunds/initiate/:transactionRef": {
    component: loadable(() => import("./../../modules/refund/InitiateRefundPage")),
  },
  "/refunds/new-refund": {
    component: loadable(() => import("./../../modules/refund/NewRefundPage")),
    exact: false,
  },
  "/refunds/:refundRef": {
    component: loadable(() => import("./../../modules/refund/RefundDetailsPage")),
    exact: false,
  },

  // payment links
  "/payment-links": {
    component: loadable(() => import("./../../modules/PaymentPages")),
  },
  "/payment-links/create": {
    component: loadable(() => import("../../modules/PaymentPages/CreatePaymentPage")),
  },
  "/payment-links/edit/:pageCode": {
    component: loadable(() => import("../../modules/PaymentPages/CreatePaymentPage")),
  },

  "/payment-link-details/subscriber/:subscriberId": {
    component: loadable(
      () => import("../../modules/PaymentPages/PaymentPageDetails/PageSubscriberDetails")
    ),
    exact: true,
  },
  "/payment-link-details/:pageReference": {
    component: loadable(() => import("./../../modules/PaymentPages/PaymentPageDetails")),
    exact: true,
  },
  "/payment-links/preview/:pageReference": {
    component: loadable(() => import("./../../modules/PaymentPages/PaymentPagePreview")),
    exact: true,
  },

  // Workflow
  "/pending-workflow-approvals": {
    component: loadable(() => import("./../../modules/workflow/PendingApproval")),
  },
  "/pending-workflow-approvals/:reference": {
    component: loadable(
      () => import("../../modules/workflow/WorkflowDetailsPage/PendingApprovalDetailsPage")
    ),
  },

  "/my-workflow-requests": {
    component: loadable(() => import("./../../modules/workflow/MyRequest")),
    exact: true,
  },
  "/my-workflow-requests/:reference": {
    component: loadable(
      () => import("../../modules/workflow/WorkflowDetailsPage/MyWorkflowDetailsPage")
    ),
    exact: true,
  },
  // home
  // "/home": {
  //   component: loadable(() => import("./../../modules/home"))
  // },

  // "/invoice": {
  //     component: loadable(() => import("./../../modules/invoice/InvoicePage"))
  // },
  // "/invoice/create": {
  //     component: loadable(() => import("./../../modules/invoice/CreateInvoicePage"))
  // },

  "/settings": {
    component: loadable(() => import("./../../modules/settings")),
  },
  "/developer": {
    component: loadable(() => import("./../../modules/developer")),
  },
  "/developer/event-logs/details": {
    component: loadable(() => import("../../modules/developer/Developer/EventLogAttempts")),
  },

  "/settlements": {
    component: loadable(() => import("./../../modules/payout/PayoutPage")),
  },
  "/settlements/transaction/:reference": {
    component: loadable(() => import("./../../modules/payout/PayoutTransactionsPage")),
  },

  "/tills": {
    component: loadable(() => import("./../../modules/tills/TillsPage")),
  },
  "/devices": {
    component: loadable(() => import("./../../modules/tills/TillDevicesPage")),
  },
  "/devices/:deviceCode": {
    component: loadable(() => import("./../../modules/tills/TillDevicesPage/DeviceDetailPage")),
  },
  "/till/create": {
    component: loadable(() => import("./../../modules/tills/CreateTillPage")),
  },

  "/till/transaction-detail/:tillRereference": {
    component: loadable(() => import("./../../modules/tills/TillsPage/TransactionDetailPage")),
  },
  "/till/details": {
    component: loadable(() => import("./../../modules/tills/TillsPage")),
  },
  "/till/account/details/:reference": {
    component: loadable(() => import("./../../modules/tills/TillAccountDetailPage")),
  },
  "/tills/:tillReference": {
    component: loadable(() => import("./../../modules/tills/TillsTransactionsPage")),
  },

  "/transfers": {
    component: loadable(() => import("./../../modules/disbursement")),
  },
  "/make-transfer": {
    component: loadable(() => import("./../../modules/disbursement/Transfer")),
  },
  "/transfers/beneficiaries": {
    component: loadable(() => import("./../../modules/disbursement/Beneficiaries")),
  },
  "/bulk-transfer/:reference": {
    component: loadable(
      () => import("./../../modules/disbursement/BulkTransfers/BulkListDetailsPage")
    ),
  },
  "/make-transfer/bulk-upload-preview": {
    component: loadable(
      () => import("../../modules/disbursement/BulkTransfers/BulkTransferUploadPreviewPage")
    ),
  },

  "/wallet/disbursement": {
    component: loadable(() => import("./../../modules/wallet/WalletWithdrawalPage")),
  },

  "/add-business": {
    component: loadable(() => import("./../../modules/add-business")),
  },

  "/balances": {
    component: loadable(() => import("./../../modules/wallet")),
  },

  "/protect-your-account": {
    component: loadable(() => import("./../../modules/multi-factor-authentication")),
  },

  "*": {
    component: loadable(() => import("./../../modules/dashboard")),
  },
};

class Routes extends React.Component {
  timeoutId = null;

  // componentDidMount() {
  //   this.timeoutId = setTimeout(
  //     () =>
  //       Object.keys(loadableRoutes).forEach((path) =>
  //         loadableRoutes[path].component.preload()
  //       ),
  //     5000 // load after 5 sec
  //   );
  // }

  // componentWillUnmount() {
  //   if (this.timeoutId) {
  //     clearTimeout(this.timeoutId);
  //   }
  // }

  render() {
    return (
      <ConnectedSwitch>
        <Route exact path="/" component={LoginPage} />
        <Route path="/health">
          <h3>Hey There!!! The App is Healthy</h3>
        </Route>

        {Object.keys(loadableRoutes).map((path) => {
          const { exact, ...props } = loadableRoutes[path];
          props.exact = exact === void 0 || exact || false; // set true as default
          return <Route key={path} path={path} {...props} />;
        })}
        {/* TODO: FIX ROUTING  */}
        {/* <Route
          render={() => (
            <Page>
              <NotFoundPage />
            </Page>
          )}
        /> */}
      </ConnectedSwitch>
    );
  }
}

export { loadableRoutes };
export default Routes;
