import { push } from "react-router-redux";
import { notification } from "antd";
import _ from "lodash";
import { _setFrom, _setHideLogin, setUserState, setLayoutState } from "./../../reducers/app";
import api from "../api";
import * as app from "../../reducers/app";
import {
  clearFormError,
  showFormError,
  addFormMessage,
  clearFormMessage,
  showMessage,
  showError,
  removeExpiredVisitedRoutes,
  getMostVisitedRoute,
  setAppEnvironment,
} from "./../../actions";
import {
  goToMfaSetupCompletePage,
  hasAccessToken,
  mfaLoading,
  mfaLogin,
  requestOtpOn2faSubsequentLogin,
  saveContactDetails,
  saveMfaDetails,
} from "./mfa";
import DataStore from "../../dataStore";
import { DATA_STORE_KEYS } from "../../dataStore/keys";
import config from "../../config";
import { setKycOnLogin, saveUserAuthorities, setLoginStatus } from "../onboarding";
import { resetEnvironment } from "../../../modules/util/EnvironmentAware/action/index";
import { SHOW_SELECT_BUSINESS_MODAL, BUSINESSES_CLEARED_FROM_STORE, CLEAN_STATE } from "../types";

import { switchBusinessDispatch } from "../businesses";
import Util, { DEFAULT_PRE_VERIFIED_HOME_ROUTE } from "../../utils";
import CryptoUtil from "../../utils/cryptoUtils";
import { OTP_INTENT } from "../../utils/otpUtils";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "../../constants/mixpanel";
import { customerIO } from "../../components/CustomerIO";

export const LOGIN_FORM = "loginForm";
export const CREATE_ACCOUNT_FORM = "createAccountForm";
export const FORGOT_PASSWORD_FORM = "forgotPasswordForm";
export const SET_PASSWORD_FORM = "setPasswordForm";
export const COMPLETE_RESET_PASSWORD_FORM = "completeResetPasswordForm";

export const USER_DETAILS_API = "/auth/userDetails";
const WELCOME_MESSAGE = "Welcome to Monnify!";

const saveLoginInformation = ({
  accessToken,
  user,
  merchantKycStatus,
  businessType,
  selectedMerchant,
  merchantList,
  authorities,
  userMFAConfig,
}) => {
  DataStore.save(DATA_STORE_KEYS.ACCESS_TOKEN, accessToken);
  DataStore.save(DATA_STORE_KEYS.USER_DATA, user);
  DataStore.save(DATA_STORE_KEYS.KYC_STATUS, merchantKycStatus);
  DataStore.save(DATA_STORE_KEYS.BUSINESS_TYPE, businessType);
  DataStore.save(DATA_STORE_KEYS.AUTHORITIES, authorities);
  DataStore.save(DATA_STORE_KEYS.USER_MFA_CONFIG, userMFAConfig);
  if (selectedMerchant)
    DataStore.save(DATA_STORE_KEYS.ACTIVE_BUSINESS, {
      businessName: selectedMerchant.merchantBusinessName,
      merchantCode: selectedMerchant.merchantCode,
      merchantAllowedFeatures: selectedMerchant.merchantAllowedFeatures,
      merchantEmail: selectedMerchant.merchantEmail,
    });
  else if (merchantList.length === 1)
    DataStore.save(DATA_STORE_KEYS.ACTIVE_BUSINESS, merchantList[0]);
  DataStore.save(DATA_STORE_KEYS.MERCHANT_BUSINESSES, merchantList);
};

const showWelcomeMessage = () => {
  notification.open({
    type: "success",
    message: "You have successfully logged in!",
    description: WELCOME_MESSAGE,
  });
};

const goToMostVisitedRoute = (dispatch) => {
  const mostVisitedRoute = getMostVisitedRoute();
  if (mostVisitedRoute) {
    dispatch(push(mostVisitedRoute));
  }
};

const clearMfaAuthInformation = () => {
  DataStore.delete(DATA_STORE_KEYS.AUTH_CODE);
  DataStore.delete(DATA_STORE_KEYS.CODE_VERIFIER);
};

export const onSuccessfulLogin = (response, dispatch, getState, options = {}) => {
  const responseBody = response?.data?.responseBody || {};
  const { user = {}, userMFAConfig = {}, accessToken } = responseBody;
  const isOtpClient = userMFAConfig?.mfaComplete && user?.phoneVerified && userMFAConfig.mfaConsent;

  if (!isOtpClient) {
    dispatch(app.deleteSubmitForm(LOGIN_FORM));
    dispatch(mfaLoading(false));
  }

  const viewDashboard = "MFO_VIEW_DASHBOARD";
  const viewTransactions = "MFO_VIEW_TRANSACTIONS";
  const viewReservedAccounts = "MFO_VIEW_RESERVED_ACCOUNTS";
  const viewSubAccounts = "MFO_VIEW_SUB_ACCOUNTS";
  const viewSettlement = "MFO_VIEW_SETTLEMENTS";
  const viewDisbursement = "MFO_VIEW_DISBURSEMENT_FEATURE";
  const viewTills = "MFO_VIEW_TILL_ACCOUNTS";

  const canViewDashboard = responseBody.authorities?.some((role) => role === viewDashboard);
  const canViewTransactions = responseBody.authorities?.some((role) => role === viewTransactions);
  const canViewReservedAccounts = responseBody.authorities?.some(
    (role) => role === viewReservedAccounts
  );
  const canViewSubAccounts = responseBody.authorities?.some((role) => role === viewSubAccounts);
  const canViewSettlement = responseBody.authorities?.some((role) => role === viewSettlement);
  const canViewDisbursement = responseBody.authorities?.some((role) => role === viewDisbursement);
  const canViewTills = responseBody.authorities?.some((role) => role === viewTills);

  if (responseBody.accessToken) {
    const { merchantKycStatus } = responseBody;
    const merchantData = responseBody;

    saveLoginInformation(merchantData);
    clearMfaAuthInformation();
    const mfaDetails = { user, accessToken, ...userMFAConfig };
    dispatch(saveMfaDetails(mfaDetails));
    // if (
    //   userMFAConfig &&
    //   !skipMfaCheckWithAccessToken &&
    //   !userMFAConfig?.mfaComplete &&
    //   user?.phoneVerified &&
    //   user?.emailVerified
    // ) {
    //   dispatch(setKycOnLogin(merchantData));
    //   dispatch(saveUserAuthorities(merchantData));
    //   dispatch(goToMfaPreferenceSetupPage());
    //   return;
    // }
    if (
      userMFAConfig &&
      options.intent === OTP_INTENT.VERIFY_MOBILE &&
      userMFAConfig?.mfaComplete
    ) {
      dispatch(setKycOnLogin(merchantData));
      dispatch(saveUserAuthorities(merchantData));
      dispatch(goToMfaSetupCompletePage());
      return;
    }
    if (merchantData.merchantList.length < 1) {
      dispatch(push("/add-business"));
      return;
    }

    if (!merchantData.selectedMerchant && merchantData.merchantList.length !== 1) {
      dispatch({ type: SHOW_SELECT_BUSINESS_MODAL });
    }

    if (!merchantData.merchantKycStatus && merchantData.merchantList.length > 1) {
      dispatch({ type: SHOW_SELECT_BUSINESS_MODAL });
    }

    if (!merchantData.merchantKycStatus && merchantData.merchantList.length === 1) {
      const { merchantCode } = merchantData.merchantList[0];
      switchBusinessDispatch({ request: { merchantCode } }, dispatch, getState);
      return;
    }

    dispatch(setKycOnLogin(merchantData));
    dispatch(saveUserAuthorities(merchantData));

    dispatch(app.deleteSubmitForm(LOGIN_FORM));
    dispatch(clearFormError(LOGIN_FORM));
    dispatch(setLoginStatus(true));

    dispatch(_setHideLogin(true));
    if (merchantKycStatus === "PENDING_BUSINESS_TYPE") {
      // dispatch(push("/onboard"));
    } else if (merchantKycStatus === "PENDING_KYC_VERIFICATION") {
      dispatch(push(DEFAULT_PRE_VERIFIED_HOME_ROUTE));
    } else if (canViewDashboard) {
      dispatch(push("/dashboard"));
    } else if (canViewTransactions) {
      dispatch(push("/transactions"));
    } else if (canViewReservedAccounts) {
      dispatch(push("/reserved-accounts"));
    } else if (canViewSubAccounts) {
      dispatch(push("/sub-accounts"));
    } else if (canViewSettlement) {
      dispatch(push("/settlements"));
    } else if (canViewDisbursement) {
      dispatch(push("/transfers"));
    } else if (canViewTills) {
      dispatch(push("/tills"));
    } else {
      dispatch(push("/settings"));
    }

    showWelcomeMessage();
    goToMostVisitedRoute(dispatch);
    removeExpiredVisitedRoutes();
    mixpanel.register({
      merchant: responseBody.selectedMerchant.merchantBusinessName,
    });
    mixpanel.identify(user.email);
    mixpanel.track(MIXPANEL_EVENTS.LOGIN);
    mixpanel.people.set({
      email: user.email,
    });
  } else if (responseBody.authCode) {
    const { authCode } = responseBody;
    // const mfaPhaseOneCompleted = !userMFAConfig?.mfaComplete && user?.phoneVerified;
    const isReturningMfaUser =
      !userMFAConfig.mfaConsent && userMFAConfig.mfaComplete && user?.phoneVerified;
    DataStore.save(DATA_STORE_KEYS.AUTH_CODE, authCode);
    dispatch(saveMfaDetails({ authCode, user, isReturningMfaUser, ...userMFAConfig }));
    dispatch(saveContactDetails(user));
    if (isOtpClient) {
      dispatch(requestOtpOn2faSubsequentLogin());
      // } else if (mfaPhaseOneCompleted) {
      // dispatch(goToMfaPreferenceSetupPage());
    } else {
      dispatch(push("/mfa-setup"));
    }
  } else {
    dispatch(showFormError(LOGIN_FORM, "Service currently unavailable"));
  }
};

export const completeLoginAfter2FA = () => (dispatch) => {
  if (hasAccessToken()) {
    dispatch(app.deleteSubmitForm(LOGIN_FORM));
    dispatch(clearFormError(LOGIN_FORM));
    dispatch(setLoginStatus(true));
    dispatch(_setHideLogin(true));
    showWelcomeMessage();
    dispatch(push("/dashboard"));
  } else {
    dispatch(mfaLogin());
  }
};

export const onLoginFailure = (error, dispatch) => {
  dispatch(app.deleteSubmitForm(LOGIN_FORM));
  dispatch(setLoginStatus(false));
  // Error
  if (error.response) {
    if (error.response.data) {
      dispatch(showFormError(LOGIN_FORM, error.response.data.responseMessage));
    } else {
      // default fallback
      dispatch(showFormError(LOGIN_FORM, "Invalid username / password combination"));
    }
  } else if (error.request) {
    if (window.navigator.onLine) {
      dispatch(showFormError(LOGIN_FORM, "Server currently offline."));
    } else {
      dispatch(
        showFormError(
          LOGIN_FORM,
          "Network error. Please check your internet connection and try again."
        )
      );
    }
  } else {
    // An occurrence in setting up the request triggered an Error
    dispatch(showFormError(LOGIN_FORM, "System error"));
  }

  dispatch(_setFrom(""));
};

export const initLogin = () => (dispatch) => {
  const authorization = DataStore.get(DATA_STORE_KEYS.ACCESS_TOKEN);
  const kycStatus = DataStore.get(DATA_STORE_KEYS.KYC_STATUS);

  handleMfaAuthorization(dispatch);
  if (authorization) {
    if (kycStatus !== "COMPLETED") {
      dispatch(push(DEFAULT_PRE_VERIFIED_HOME_ROUTE));
    } else {
      dispatch(push("/dashboard"));
    }
  }
};

export const setLayoutStateAction = (values) => (dispatch) =>
  dispatch(dispatch(setLayoutState(values)));
// logs out a user and deletes all role data
export const logout = () => (dispatch) => {
  const _emptyAuth = { authorities: [] };
  dispatch({ type: BUSINESSES_CLEARED_FROM_STORE });
  dispatch(setUserState({ userState: {} }));
  dispatch(saveUserAuthorities(_emptyAuth));
  if (window.fcWidget) {
    window.fcWidget.destroy();
  }
  const backupPrevioulsyOnLIveView = DataStore.get(DATA_STORE_KEYS.WAS_PREVIOUSLY_ON_LIVE_VIEW);
  const backupFreshChatRestoreId = DataStore.get(DATA_STORE_KEYS.FRESCHAT_RESTORE_ID);
  const backupVisitedPages = DataStore.get(DATA_STORE_KEYS.VISITED_PAGES);
  dispatch(resetEnvironment());
  dispatch({ type: CLEAN_STATE });
  DataStore.clear();
  DataStore.save(DATA_STORE_KEYS.WAS_PREVIOUSLY_ON_LIVE_VIEW, backupPrevioulsyOnLIveView);
  DataStore.save(DATA_STORE_KEYS.FRESCHAT_RESTORE_ID, backupFreshChatRestoreId);
  DataStore.save(DATA_STORE_KEYS.VISITED_PAGES, backupVisitedPages);
  dispatch(push("/login"));
  customerIO.reset();
};

const validatePageAuthorities = (requiredAuthorities, authorities) => {
  let hasAuthority = false;

  if (!requiredAuthorities || (requiredAuthorities && requiredAuthorities.length < 1)) {
    return true;
  }

  _.forEach(requiredAuthorities, function (requiredAuthority) {
    for (let i = 0; i < authorities.length; i++) {
      if (requiredAuthority === authorities[i]) {
        hasAuthority = true;
        break;
      }
    }
  });

  return hasAuthority;
};

const initEnvironmentSwitchState = (dispatch) => {
  let savedEnvironmentState = DataStore.get(DATA_STORE_KEYS.APP_ENVIRONMENT);
  const lastViewMode = window.localStorage.getItem("_lastViewMode");

  if (lastViewMode !== null) {
    savedEnvironmentState = lastViewMode;
  }

  if (savedEnvironmentState !== null && savedEnvironmentState !== true) {
    // dispatch change state
    dispatch(setAppEnvironment(savedEnvironmentState));
  }
};

/**
 * handleMfaAuthorization: checks user mfa access
 * @description restrict access into the app if mfa is incomplete and user grace period is expired
 * @param {*} dispatch
 */
const handleMfaAuthorization = (dispatch) => {
  const mfaConfig = DataStore.get(DATA_STORE_KEYS.USER_MFA_CONFIG) || {};
  const user = DataStore.get(DATA_STORE_KEYS.USER_DATA) || {};
  if (
    hasAccessToken() &&
    user.emailVerified &&
    mfaConfig.gracePeriodExpired &&
    !mfaConfig.mfaComplete
  ) {
    if (user.phoneVerified) {
      // dispatch(goToMfaPreferenceSetupPage());
    } else {
      dispatch(logout());
    }
  }
};

// util function called to check if user has access to roles required to show page
export const initAuth = (requiredAuthorities) => (dispatch, getState) => {
  const authorization = DataStore.get(DATA_STORE_KEYS.ACCESS_TOKEN);
  const kycStatus = DataStore.get(DATA_STORE_KEYS.KYC_STATUS);
  const businesses = DataStore.get(DATA_STORE_KEYS.MERCHANT_BUSINESSES);

  if (!kycStatus && !businesses) {
    if (!authorization || !kycStatus) {
      dispatch(logout());
      return Promise.reject();
    }
  } else if (kycStatus && businesses) {
    const parsedToken = Util.parseJwt(authorization);
    const expiryTime = parsedToken.exp * 1000;
    const currentTime = Date.now();
    if (currentTime > expiryTime) {
      dispatch(logout());
    }
  }

  handleMfaAuthorization(dispatch);
  initEnvironmentSwitchState(dispatch);
  // update kyc status in store
  dispatch(setKycOnLogin({ merchantKycStatus: kycStatus }));
  const { userState } = getState().app;
  // update login status
  if (authorization) {
    dispatch(setLoginStatus(true));
  }
  if (!userState) {
    // let successHandler = (response) => {
    //   if (response.data && response.data.responseBody) {
    //     dispatch(setUserState({ userState: response.data.responseBody }));
    //     const hasAuthorities = validatePageAuthorities(
    //       requiredAuthorities,
    //       response.data.responseBody.authorities,
    //     );
    //     if (!hasAuthorities) {
    //       dispatch(push("/dashboard"));
    //       notification.open({
    //         type: "error",
    //         message: "you do not have access to this function",
    //       });
    //     }
    //   } else {
    //     notification.open({
    //       type: "error",
    //       message: "Server is currently offline",
    //     });
    //     return Promise.resolve(true);
    //   }
    //   return Promise.resolve(true);
    // };
    // let errorHandler = (error) => {
    //   if (error.request) {
    //     notification.open({
    //       type: "error",
    //       message: "Service is currently offline",
    //     });
    //   }
    // };
    // let dispatchObject = api.get(
    //   USER_DETAILS_API,
    //   {},
    //   successHandler,
    //   errorHandler
    // );
    // dispatch(dispatchObject);
  } else {
    const hasAuthorities = validatePageAuthorities(requiredAuthorities, userState.authorities);

    if (!hasAuthorities) {
      dispatch(push("/dashboard"));
      notification.open({
        type: "error",
        message: "you do not have access to this function",
      });
    }

    return Promise.resolve(true);
  }

  return Promise.resolve(true);
};

export const login = (data) => async (dispatch, getState) => {
  try {
    dispatch(app.addSubmitForm(LOGIN_FORM));
    dispatch(clearFormError(LOGIN_FORM));

    const codeVerifier = CryptoUtil.generateCodeVerifier();
    const codeChallenge = await CryptoUtil.sha256(codeVerifier);
    const encryptedCodeVerifier = CryptoUtil.encryptWithRSA(codeVerifier);

    data = { ...data, codeChallenge };

    const url = `${config.USER_SERVICE_URL}/auth/login`;

    dispatch(
      api.post(
        url,
        data,
        (response) => {
          onSuccessfulLogin(response, dispatch, getState);
          DataStore.save(DATA_STORE_KEYS.CODE_VERIFIER, encryptedCodeVerifier);
        },
        (error) => onLoginFailure(error, dispatch),
        true,
        {
          headers: {
            "2fa-login-enabled": true,
          },
        }
      )
    );
  } catch (error) {
    console.error(`login: ${error}`);
    dispatch(app.deleteSubmitForm(LOGIN_FORM));
    dispatch(setLoginStatus(false));
  }
};

const onSuccessfulForgotPasswordRequest = (response, dispatch) => {
  dispatch(clearFormError(FORGOT_PASSWORD_FORM));
  dispatch(clearFormMessage(FORGOT_PASSWORD_FORM));

  if (response.data && response.data.responseBody) {
    dispatch(addFormMessage(FORGOT_PASSWORD_FORM, "Link sent to email"));
  }
};

const onFailedForgotPasswordRequest = (error, dispatch) => {
  dispatch(clearFormError(FORGOT_PASSWORD_FORM));
  dispatch(clearFormMessage(FORGOT_PASSWORD_FORM));

  if (error.response) {
    if (error.response.data) {
      dispatch(showFormError(FORGOT_PASSWORD_FORM, error.response.data.responseMessage));
    } else {
      dispatch(showFormError(FORGOT_PASSWORD_FORM, "The email address is not valid"));
    }
  } else if (error.request) {
    dispatch(showFormError(FORGOT_PASSWORD_FORM, "Server currently offline"));
  } else {
    // Something happened in setting up the request that triggered an Error
    dispatch(showFormError(FORGOT_PASSWORD_FORM, "System error"));
  }
};

// TODO: pass the form Message using Redux
export const forgotPasswordCheck = (email) => (dispatch) => {
  const url = `${config.USER_SERVICE_URL}/users/initiate-password-reset?username=${email}`;

  dispatch(
    api.get(
      url,
      {},
      (response) => onSuccessfulForgotPasswordRequest(response, dispatch),
      (error) => onFailedForgotPasswordRequest(error, dispatch),
      true
    )
  );
};

export const forgotPassword = (email, successHandler, errorHandler) => (dispatch) => {
  email = _.trim(email);
  const url = `${config.USER_SERVICE_URL}/users/initiate-password-reset?username=${email}`;

  dispatch(
    api.get(
      url,
      {},
      (response) => successHandler(response),
      (error) => errorHandler(error),
      true
    )
  );
};

export const validateRequestReference =
  (requestReference, successHandler, errorHandler) => (dispatch) => {
    const _successHandler = (response) => {
      successHandler && successHandler(response, dispatch);
    };

    const _errorHandler = (error) => {
      error && errorHandler(error, dispatch);
      showError(error);
    };

    const url = `${config.USER_SERVICE_URL}/request-reference/validate?ref=${requestReference}`;

    dispatch(api.get(url, {}, _successHandler, _errorHandler, true));
  };

const onCompleteResetPasswordSuccessful = (response, dispatch) => {
  dispatch(app.deleteSubmitForm(COMPLETE_RESET_PASSWORD_FORM));

  if (response.data && response.data.responseBody) {
    showMessage(
      "Successful!",
      "Your password has been updated. You may now login with the new password."
    );
    dispatch(push("/login"));
    return;
  }
};

const onCompleteResetPasswordError = (error, dispatch) => {
  dispatch(clearFormError(COMPLETE_RESET_PASSWORD_FORM));
  dispatch(app.deleteSubmitForm(COMPLETE_RESET_PASSWORD_FORM));

  if (error.response) {
    if (error.response.data) {
      dispatch(showFormError(COMPLETE_RESET_PASSWORD_FORM, error.response.data.responseMessage));
    } else {
      dispatch(
        showFormError(
          COMPLETE_RESET_PASSWORD_FORM,
          "Unable to complete your request. Please try again!"
        )
      );
    }
  } else if (error.request) {
    dispatch(showFormError(COMPLETE_RESET_PASSWORD_FORM, "Server currently offline"));
  } else {
    // Something happened in setting up the request that triggered an Error
    dispatch(showFormError(COMPLETE_RESET_PASSWORD_FORM, "System error"));
  }
};

export const completeResetPassword = (requestReference, password) => (dispatch) => {
  dispatch(app.addSubmitForm(COMPLETE_RESET_PASSWORD_FORM));
  dispatch(clearFormError(COMPLETE_RESET_PASSWORD_FORM));

  const request = {
    requestReference,
    password,
    passwordConfirmation: password,
  };

  const url = `${config.USER_SERVICE_URL}/users/complete-password-reset`;

  dispatch(
    api.post(
      url,
      request,
      (response) => onCompleteResetPasswordSuccessful(response, dispatch),
      (error) => onCompleteResetPasswordError(error, dispatch),
      true
    )
  );
};

const onCompleteUserCreationSuccessful = (response, dispatch) => {
  dispatch(app.deleteSubmitForm(SET_PASSWORD_FORM));

  if (response.data && response.data.responseBody) {
    showMessage(
      "Successful!",
      "Your Account setup has been completed successfully. You may login now."
    );
    dispatch(push("/login"));
    return;
  }
};

const onCompleteUserCreationError = (error, dispatch) => {
  dispatch(clearFormError(SET_PASSWORD_FORM));
  dispatch(app.deleteSubmitForm(SET_PASSWORD_FORM));

  if (error.response) {
    if (error.response.data) {
      dispatch(showFormError(SET_PASSWORD_FORM, error.response.data.responseMessage));
    } else {
      dispatch(
        showFormError(SET_PASSWORD_FORM, "Unable to complete your request. Please try again!")
      );
    }
  } else if (error.request) {
    dispatch(showFormError(SET_PASSWORD_FORM, "Server currently offline"));
  } else {
    // Something happened in setting up the request that triggered an Error
    dispatch(showFormError(SET_PASSWORD_FORM, "System error"));
  }
};

export const completeUserCreation = (reference, password) => (dispatch) => {
  dispatch(app.addSubmitForm(SET_PASSWORD_FORM));
  dispatch(clearFormError(SET_PASSWORD_FORM));
  const request = {
    requestReference: reference,
    password,
    passwordConfirmation: password,
  };

  const url = `${config.USER_SERVICE_URL}/users/create-password`;

  dispatch(
    api.post(
      url,
      request,
      (response) => onCompleteUserCreationSuccessful(response, dispatch),
      (error) => {
        onCompleteUserCreationError(error, dispatch);
      },
      true
    )
  );
};
