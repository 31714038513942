export const MIXPANEL_EVENTS = {
  LOGIN: "Login",
  LOGOUT: "Logout",
  SWITCH_BUSINESS: "Switch Business",
  REGISTRATION_ATTEMPTED: "Registration Attempted",
  REGISTRATION_COMPLETED: "Registration Completed",
  BUSINESS_VERIFICATION_ATTEMPT: "Business Verification Attempt",
  EXPORT_COLLECTIONS_TRANSACTIONS: "Export Collections Transactions",
  FILTER_COLLECTIONS_TRANSACTIONS: "Apply Filter On Collections Transactions",
  VIEW_SINGLE_COLLECTIONS_TRANSACTION: "View Single Collections Transaction",
  WALLET_WITHDRAWAL: "Wallet Withdrawal",
  VIEW_SINGLE_WALLET_TRANSACTION: "View Single Wallet Transaction",
  VIEW_SINGLE_TRANSFER_TRANSACTION: "View Single Transfer Transaction",
  FILTER_WALLET_TRANSACTIONS: "Apply Filters On Wallet Transactions",
  DOWNLOAD_WALLET_STATEMENT: "Download Wallet Statement",
  FILTER_DISBURSEMENT_TRANSACTIONS: "Apply Filter On Transfers",
  VIEW_SINGLE_SETTLEMENT_TRANSACTION: "View Single Settlement Transaction",
  EXPORT_SETTLEMENTS: "Export Settlements",
};
