import React from "react";
import propTypes from "prop-types";
import NotificationBanner from "../NotificationBanner";
import { DASHBOARD_ROUTE, SETTINGS_ROUTE } from "../../../config/routes";
import { goToInAppMfaSetupPage, MFA_INAPP_PATH } from "../../../actions/auth/mfa";
import { connect } from "react-redux";
import dataStore from "../../../dataStore";
import { DATA_STORE_KEYS } from "../../../dataStore/keys";
import { getMostVisitedRoute } from "../../../actions";

const mfaDetails = dataStore.get(DATA_STORE_KEYS.USER_MFA_CONFIG) || {};
const userDetails = dataStore.get(DATA_STORE_KEYS.USER_DATA) || {};
const notificationDetails = {
  title: "Protect your account",
  description: "Protect your account by setting up Multi-Factor Authentication",
  ctaText: "Setup Now",
  variant: "warning",
};

const mapStateToProps = ({ mfa, router }) => ({
  hideNotificationBanner: mfa.data?.mfaComplete ?? mfaDetails?.mfaComplete ?? true,
  location: router.location,
  user: mfa.data?.user ?? userDetails,
});
@connect(mapStateToProps, { goToInAppMfaSetupPage })
class MfaAppNotification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: [DASHBOARD_ROUTE, SETTINGS_ROUTE],
      isVisible: false,
    };
  }

  componentDidMount() {
    this.initializeRoutes();
    this.updateVisibility();
  }

  initializeRoutes() {
    const mostVisitedRoute = getMostVisitedRoute();
    if (
      mostVisitedRoute &&
      !this.state.routes.includes(mostVisitedRoute) &&
      mostVisitedRoute !== MFA_INAPP_PATH
    ) {
      this.setState((prevState) => ({
        routes: [...prevState.routes, mostVisitedRoute],
      }));
    }
  }

  updateVisibility() {
    const { location, hideNotificationBanner } = this.props;
    const { routes } = this.state;
    const currentPath = location?.pathname;
    this.setState({
      isVisible: routes.includes(currentPath) && !hideNotificationBanner,
    });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (prevProps.location?.pathname !== location?.pathname) {
      this.updateVisibility();
    }
  }

  render() {
    const { goToInAppMfaSetupPage, user } = this.props;
    const { isVisible } = this.state;

    return (
      <NotificationBanner
        title={notificationDetails.title}
        description={notificationDetails.description}
        ctaText={notificationDetails.ctaText}
        handleCtaClick={goToInAppMfaSetupPage}
        isVisible={user?.emailVerified && isVisible}
        variant={notificationDetails.variant}
        closeable
      />
    );
  }
}

export default MfaAppNotification;

MfaAppNotification.propTypes = {
  variant: propTypes.oneOf(["warning", "info"]),
  title: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  ctaText: propTypes.string.isRequired,
  handleCtaClick: propTypes.func.isRequired,
  isVisible: propTypes.bool.isRequired,
  closeable: propTypes.bool,
  goToInAppMfaSetupPage: propTypes.func,
  location: propTypes.object,
  user: propTypes.object,
  hideNotificationBanner: propTypes.bool,
};
