export const FORM_SUBMIT_ERROR = "form_submit_error";
export const CLEAR_FORM_SUBMIT_ERROR = "clear_form_submit_error";
export const MAKE_API_CALL = "make_api_call";
export const PURGE_STATE = "PURGE_STATE";
export const CLEAN_STATE = "CLEAN_STATE";

export const ADD_FORM_MESSAGE = "add_form_message";
export const CLEAR_FORM_MESSAGE = "clear_form_message";

// multi-user businesses
export const SHOW_SELECT_BUSINESS_MODAL = "SHOW_SELECT_BUSINESS_MODAL";
export const TOGGLE_SHOW_SELECT_BUSINESS_MODAL = "TOGGLE_SHOW_SELECT_BUSINESS_MODAL";
export const BUSINESSES_CLEARED_FROM_STORE = "BUSINESSES_CLEARED_FROM_STORE";
export const ACTIVE_BUSINESS_SET = "ACTIVE_BUSINESS_SET";
export const ACTIVE_BUSINESS_UNSET = "ACTIVE_BUSINESS_UNSET";
export const UPDATE_SWITCH_BUSINESS_LOADING = "UPDATE_SWITCH_BUSINESS_LOADING";
export const TOGGLE_BUSINESS_REFRESH = "TOGGLE_BUSINESS_REFRESH";
export const CREATE_BUSINESS_LOADING = "CREATE_BUSINESS_LOADING";
export const UPDATE_BUSINESS_DETAILS_LOADING = "UPDATE_BUSINESS_DETAILS_LOADING";
export const GET_BUSINESS_DETAILS_LOADING = "GET_BUSINESS_DETAILS_LOADING";
export const SAVE_BUSINESS_DETAILS = "SAVE_BUSINESS_DETAILS";
export const UPDATE_BUSINESS_LOGO_LOADING = "UPDATE_BUSINESS_LOGO_LOADING";
export const SAVE_BUSINESS_LOGO = "SAVE_BUSINESS_LOGO";
export const CLEAR_BUSINESS_LOGO = "CLEAR_BUSINESS_LOGO";

// onboarding
export const SET_KYC = "SET_KYC";
export const SAVE_KYC_STATUS = "SAVE_KYC_STATUS";
export const KYC_ACTIVATION_STATUS_LOADING = "KYC_ACTIVATION_STATUS_LOADING";
export const KYC_ACTIVATION_STATUS_SUCCESS = "KYC_ACTIVATION_STATUS_SUCCESS";
export const SAVE_STATIC_KYC_INFORMATION = "SAVE_STATIC_KYC_INFORMATION";
export const SET_KYC_ON_LOGIN = "SET_KYC_ON_LOGIN";
export const SELF_SIGN_UP_SUCCESS = "self_sign_up_success";
export const SELF_SIGN_UP_LOADING = "self_sign_up_loading";
export const SAVE_BUSINESS_TYPE_DATA = "save_type_of_business_data";
export const SAVE_BUSINESS_TYPE_LOADING = "save_type_of_business_loading";
export const KYC_VERIFY_PROFILE_SUCCESS = "kyc_verify_profile_success";
export const KYC_VERIFY_PROFILE_LOADING = "kyc_verify_profile_loading";
export const KYC_VERIFY_BANK_LOADING = "kyc_verify_bank_loading";
export const KYC_VERIFY_BANK_SUCCESS = "kyc_verify_bank_success";
export const KYC_VERIFY_BUSINESS_SUCCESS = "kyc_verify_business_success";
export const KYC_VERIFY_BUSINESS_LOADING = "kyc_verify_business_loading";
export const VALIDATE_KYC_STATUS = "validate_kyc_status";
export const GET_BUSINESS_CATEGORIES = "get_business_categories";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRY_STATES = "GET_COUNTRY_STATES";
export const GET_STATE_LGAS = "GET_STATE_LGAS";
export const GET_LGA = "GET_LGA";
export const GET_COMPANY_REG_TYPES_SUCCESS = "GET_COMPANY_REG_TYPES_SUCCESS";
export const GET_COMPANY_REG_TYPES_LOADING = "GET_COMPANY_REG_TYPES_LOADING";
export const GET_ID_TYPES_SUCCESS = "GET_ID_TYPES_SUCCESS";
export const GET_ID_TYPES_LOADING = "GET_ID_TYPES_LOADING";
export const SWITCH_BUSINESS_TYPE_LOADING = "SWITCH_BUSINESS_TYPE_LOADING";
export const SWITCH_BUSINESS_TYPE_SUCCESS = "SWITCH_BUSINESS_TYPE_SUCCESS";
export const SAVE_CURRENT_FILTER = "SAVE_CURRENT_FILTER";
export const UPDATE_KYC_VERIFICATION_SUCCESS = "UPDATE_KYC_VERIFICATION_SUCCESS";
export const UPDATE_BANK_VERIFICATION_SUCCESS = "UPDATE_BANK_VERIFICATION_SUCCESS";
export const UPDATE_BANK_VERIFICATION_LOADING = "UPDATE_BANK_VERIFICATION_LOADING";
export const UPDATE_BUSINESS_VERIFICATION_SUCCESS = "UPDATE_BUSINESS_VERIFICATION_SUCCESS";
export const UPDATE_BUSINESS_VERIFICATION_LOADING = "UPDATE_BUSINESS_VERIFICATION_LOADING";
export const VALIDATE_EMAIL_LOADING = "VALIDATE_EMAIL_LOADING";
export const VALIDATE_EMAIL_SUCCESS = "VALIDATE_EMAIL_SUCCESS";
export const VALIDATE_EMAIL_FAILURE = "VALIDATE_EMAIL_FAILURE";
export const UPDATE_VERIFY_PROFILE_FLAG = "UPDATE_VERIFY_PROFILE_FLAG";
export const UPDATE_VERIFY_BANK_FLAG = "UPDATE_VERIFY_BANK_FLAG";
export const UPDATE_VERIFY_BUSINESS_FLAG = "UPDATE_VERIFY_BUSINESS_FLAG";
export const KYC_VERIFY_COMPANY_SUCCESS = "KYC_VERIFY_COMPANY_SUCCESS";
export const KYC_VERIFY_COMPANY_LOADING = "KYC_VERIFY_COMPANY_LOADING";
export const UPDATE_COMPANY_REGISTRATION_SUCCESS = "UPDATE_COMPANY_REGISTRATION_SUCCESS";
export const UPDATE_COMPANY_REGISTRATION_LOADING = "UPDATE_COMPANY_REGISTRATION_LOADING";
export const COMPLETE_USER_INVITATION = "COMPLETE_USER_INVITATION";
export const COMPLETE_USER_INVITE_LOADING = "COMPLETE_USER_INVITE_LOADING";
export const ACTIVATE_USER_INVITATION = "ACTIVATE_USER_INVITATION";
export const ACTIVATE_USER_INVITATION_LOADING = "ACTIVATE_USER_INVITATION_LOADING";
export const ACTIVATE_USER_INVITATION_SUCCESS = "ACTIVATE_USER_INVITATION_SUCCESS";
export const ACTIVATE_USER_INVITATION_FAILURE = "ACTIVATE_USER_INVITATION_FAILURE";
export const ACTIVATE_USER_INVITATION_STATUS = "ACTIVATE_USER_INVITATION_STATUS";
export const SAVE_USER_AUTHORITIES = "SAVE_USER_AUTHORITIES";

export const GET_APPLICABLE_KYC = "get_applicable_kyc";
export const UPDATE_APPLICABLE_KYC_LOADING = "UPDATE_APPLICABLE_KYC_LOADING";
export const LOGIN_STATUS = "LOGIN_STATUS";

// dashboard
export const SAVE_DASHBOARD_STATIC_DATA = "SAVE_DASHBOARD_STATIC_DATA";
export const SAVE_DASHBOARD_GRAPH_DATA = "SAVE_DASHBOARD_GRAPH_DATA";
export const UPDATE_DASHBOARD_LOADING = "update_dashboard_loading";
export const UPDATE_DASHBOARD_GRAPH_LOADING = "update_dashboard_graph_loading";
export const UPDATE_DASHBOARD_STATIC_LOADING = "update_dashboard_static_loading";

// payout
export const SAVE_BENEFICIARY_PAYOUT = "save_beneficiary_payout";
export const SAVE_PAYOUT_TRANSACTION = "save_payout_transaction";
export const UPDATE_PAYOUT_LOADING = "update_payout_loading";
export const UPDATE_PAYOUT_TRANSACTION_LOADING = "update_payout_transaction_loading";
export const IS_PAYOUT_FILTER = "IS_PAYOUT_FILTER";

// transactions
export const SAVE_TRANSACTION_DATA = "save_transaction_data";
export const SAVE_TRANSACTION_LIST_DATA = "SAVE_TRANSACTION_LIST_DATA";
export const UPDATE_TRANSACTIONS_LOADING = "update_transactions_loading";
export const SAVE_TRANSACTION_DETAILS = "save_transaction_details";
export const SAVE_RECENT_TRANSACTION_DATA = "save_recent_transaction_data";
export const IS_TRANSACTIONS_FILTER = "IS_TRANSACTIONS_FILTER";
export const SAVE_TRANSACTION_FILTER_PARAMS = "SAVE_TRANSACTION_FILTER_PARAMS";

// reserved accounts
export const SAVE_RESERVED_ACCOUNTS_LIST = "save_reserved_accounts_list";
export const UPDATE_RESERVED_ACCOUNTS_LOADING = "update_reserved_accounts_loading";
export const SAVE_RESERVED_ACCOUNT_DETAILS = "save_reserved_account_details";
export const SAVE_RESERVED_ACCOUNT_TRANSACTIONS_LIST = "save_reserved_account_transactions_list";
export const UPDATE_RESERVED_ACCOUNT_TRANSACTIONS_LOADING =
  "save_reserved_account_transactions_loading";
export const UPDATE_DEALLOCATE_RESERVED_ACCOUNT_PROCESSING =
  "update_delete_reserved_account_processing";
export const UPDATE_CREATE_RESERVE_ACCOUNT_PROCESSING = "update_create_reserve_account_processing";
export const IS_RESERVED_ACCOUNT_FILTER = "IS_RESERVED_ACCOUNT_FILTER";

// sub accounts
export const GET_SUB_ACCOUNTS_LIST = "get_sub_accounts_list";
export const SAVE_SUB_ACCOUNTS_LIST = "save_sub_accounts_list";
export const UPDATE_SUB_ACCOUNTS_LOADING = "update_sub_accounts_loading";
export const CREATE_SUB_ACCOUNT = "create_sub_account";
export const DELETE_SUB_ACCOUNT = "delete_sub_account";
export const DELETE_SUB_ACCOUNT_SUCCESS = "delete_sub_account_success";
export const SUB_ACCOUNTS_PROCESSING = "sub_account_processing";
export const SUB_ACCOUNTS_PROCESSED = "sub_account_processed";
export const SUB_ACCOUNTS_DELETING = "sub_account_deleting";
export const SUB_ACCOUNTS_DELETED = "sub_account_deleted";

// banks
export const SAVE_BANKS = "save_banks";

// contracts
export const SAVE_CONTRACTS_LIST = "save_contracts_list";
export const SAVE_CONTRACTS_PAGE_DATA = "save_contracts_page_data";
export const SAVE_CONTRACT_DETAILS = "save_contract_details";
export const UPDATE_CONTRACTS_LOADING = "update_contracts_loading";
export const UPDATE_PAYMENT_METHOD_PROCESSED = "UPDATE_PAYMENT_METHOD_PROCESSED";

// api actions calls
export const STORE_AVAILABLE_PAYMENT_CHANNEL_MAPPINGS = "store_available_payment_channel_mappings";

// authentication calls
export const AUTHENTICATION_VALIDATE_PASSWORD_PROCESSING =
  "authentication_validate_password_processing";
export const AUTHENTICATION_VALIDATE_PASSWORD_ERROR_UPDATE =
  "authentication_validate_password_error_update";

// merchant calls
export const SAVE_MERCHANT_CLIENT_DETAILS = "save_merchant_client_details";
export const UPDATE_MERCHANT_CLIENT_DETAILS_LOADING = "update_merchant_client_details_loading";
export const GET_MERCHANT_CLIENT_CONTRACTS = "get_merchant_client_contracts";
export const UPDATE_CLIENT_VALIDATION_LOADING = "update_client_validation_loading";
export const GET_MERCHANT_NOTIFICATION = "get_merchant_notification";
export const UPDATE_MERCHANT_NOTIFICATION = "update_merchant_notification";
export const SAVE_PAYMENT_METHOD_STATUS = "save_payment_method_status";
export const UPDATE_PAYMENT_METHOD_STATUS_LOADING = "update_payment_method_status_loading";
export const SAVE_MERCHANT_SETTLEMENT_ACCOUNT = "save_merchant_settlement_account";
export const UPDATE_BANK_ACCOUNTS_LOADING = "update_bank_accounts_loading";
export const UPDATE_CONTRACT_SETTINGS_LOADING = "update_contract_settings_loading";
export const UPDATE_MERCHANT_SETTLEMENT_LOADING = "update_merchant_settlement_loading";
export const CLOSE_MERCHANT_MODAL = "close_merchant_modal";
export const UPDATE_BANK_ACCOUNTS_PROCESSED = "update_bank_accounts_processed";
export const SAVE_MERCHANT_SETTLEMENT_ACCOUNT_UPDATE_REQUEST =
  "SAVE_MERCHANT_SETTLEMENT_ACCOUNT_UPDATE_REQUEST";
export const UPDATE_BANK_ACCOUNT_UPDATE_REQUEST_LOADING =
  "UPDATE_BANK_ACCOUNT_UPDATE_REQUEST_LOADING";

// environment
export const UPDATE_APP_ENVIRONMENT = "update_app_environment";

// invoices
export const SAVE_INVOICES_LIST = "save_invoice_list";
export const GET_ALL_INVOICES = "get_invoice_list";
export const UPDATE_INVOICES_LOADING = "update_invoices_loading";

// tills
export const SAVE_TILLS_DATA = "SAVE_TILLS_DATA";
export const UPDATE_TILLS_LOADING = "UPDATE_TILLS_LOADING";
export const UPDATE_TILL_DETAIL_LOADING = "UPDATE_TILL_DETAIL_LOADING";
export const SAVE_TILL_DETAIL_DATA = "SAVE_TILL_DETAIL_DATA";
export const SAVE_TILL_TRANSACTION_DATA = "SAVE_TILL_TRANSACTION_DATA";
export const UPDATE_DEALLOCATE_TILL_ACCOUNT_PROCESSING =
  "UPDATE_DEALLOCATE_TILL_ACCOUNT_PROCESSING";

// settlment notification
export const GET_SETTLEMENT_NOTIFICATION_PREFERENCE = "GET_SETTLEMENT_NOTIFICATION_PREFERENCE";

// disbursements
export const SAVE_DISBURSEMENT_WALLETS = "SAVE_DISBURSEMENT_WALLETS";
export const IS_WALLET_FILTER = "IS_WALLET_FILTER";
export const UPDATE_DISBURSEMENT_TRANSACTIONS_LOADING = "UPDATE_DISBURSEMENT_TRANSACTIONS_LOADING";
export const UPDATE_DISBURSEMENTS_DETAIL_LOADING = "UPDATE_DISBURSEMENTS_DETAIL_LOADING";
export const SAVE_DISBURSEMENT_TRANSACTIONS = "SAVE_DISBURSEMENT_TRANSACTIONS";
export const SAVE_WALLET_TOP_UP_DETAIL = "SAVE_WALLET_TOP_UP_DETAIL";
export const UPDATE_DISBURSEMENT_TRANSACTIONS_DETAIL_LOADING =
  "UPDATE_DISBURSEMENT_TRANSACTIONS_DETAIL_LOADING";
export const SAVE_DISBURSEMENT_TRANSACTION_DETAIL = "SAVE_DISBURSEMENT_TRANSACTION_DETAIL";
export const IS_DISBURSEMENT_FILTER = "IS_DISBURSEMENT_FILTER";
// merchant disbursements
export const GET_DISBURSEMENT_PREFERENCES = "GET_DISBURSEMENT_PREFERENCES";
export const SAVE_TRANSFER_BENEFICIARY = "SAVE_TRANSFER_BENEFICIARY";
export const UPDATE_TRANSFER_BENEFICIARY_LOADING = "UPDATE_TRANSFER_BENEFICIARY_LOADING";
export const SAVE_TRANSFER_DETAILS_SUMMARY = "SAVE_TRANSFER_DETAILS_SUMMARY";
export const SAVE_BULK_UPLOAD_DETAILS = "SAVE_BULK_UPLOAD_DETAILS";
export const SAVE_BULK_TRANSACTIONS = "SAVE_BULK_TRANSACTIONS";
export const UPDATE_BULK_TRANSACTIONS_LOADING = "UPDATE_BULK_TRANSACTIONS_LOADING";
export const SAVE_BULK_TRANSACTION_DETAILS = "SAVE_BULK_TRANSACTION_DETAILS";
// export const UPDATE_BULK_TRANSACTIONS_DETAILS_LOADING = "UPDATE_BULK_TRANSACTIONS_DETAILS_LOADING"

// teams

export const GET_AUTHORITIES = "GET_AUTHORITIES";
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const CREATE_ROLE = "CREATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const TEAMS_ISLOADING = "TEAMS_ISLOADING";
export const TEAMS_ISPROCESSING = "TEAMS_ISPROCESSING";
export const INVITE_SENT = "INVITE_SENT";
export const GET_MERCHANT_USERS = "GET_MERCHANT_USERS";
export const GET_MERCHANT_USERS_LOADING = "GET_MERCHANT_USERS_LOADING";
// wallets
export const SAVE_WALLET = "SAVE_WALLET";
export const UPDATE_WALLET_LOADING = "UPDATE_WALLET_LOADING";
export const SAVE_WALLET_DETAIL = "SAVE_WALLET_DETAIL";
export const SAVE_WALLET_TRANSACTIONS = "SAVE_WALLET_TRANSACTIONS";
export const UPDATE_WALLET_TRANSACTIONS_LOADING = "UPDATE_WALLET_TRANSACTIONS_LOADING";
export const INITIATE_TRANSFER_LOADING = "INITIATE_TRANSFER_LOADING";
export const INITIATE_TRANSFER_SUCCESS = "INITIATE_TRANSFER_SUCCESS";
export const VERIFY_TRANSFER_SUCCESS = "VERIFY_TRANSFER_SUCCESS";
export const VERIFY_TRANSFER_STATUS = "VERIFY_TRANSFER_STATUS";
export const VERIFY_TRANSFER_FAILURE = "VERIFY_TRANSFER_FAILURE";
export const VERIFY_TRANSFER_LOADING = "VERIFY_TRANSFER_LOADING";
export const VERIFY_TRANSFER_RESPONSE_CODE = "VERIFY_TRANSFER_RESPONSE_CODE";
export const VERIFY_TRANSFER_CANCEL = "VERIFY_TRANSFER_CANCEL";
export const RESEND_OTP_START = "RESEND_OTP_START";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";
export const INITIATE_RETRY_OTP = "INITIATE_RETRY_OTP";

// offline payments
export const SAVE_OFFLINE_MERCHANT_PRODUCTS = "SAVE_OFFLINE_MERCHANT_PRODUCTS";
export const SAVE_OFFLINE_MERCHANT_PRODUCT_TRANSACTIONS =
  "SAVE_OFFLINE_MERCHANT_PRODUCT_TRANSACTIONS";
export const UPDATE_OFFLINE_MERCHANT_PRODUCTS_LOADING = "UPDATE_OFFLINE_MERCHANT_PRODUCTS_LOADING";
export const UPDATE_OFFLINE_MERCHANT_PRODUCT_TRANSACTIONS_LOADING =
  "UPDATE_OFFLINE_MERCHANT_PRODUCT_TRANSACTIONS_LOADING";
export const SAVE_OFFLINE_MERCHANT_PRODUCT_DETAILS = "SAVE_OFFLINE_MERCHANT_PRODUCT_DETAILS";
export const SAVE_OFFLINE_MERCHANT_PRODUCT_ACCOUNT_SUMMARY =
  "SAVE_OFFLINE_MERCHANT_PRODUCT_ACCOUNT_SUMMARY";
export const GET_HISTORICAL_DATA = "GET_HISTORICAL_DATA";
export const GET_HISTORICAL_DATA_STATUS = "GET_HISTORICAL_DATA_STATUS";
export const CLEAR_OFFLINE_MERCHANT_PRODUCT_DETAILS = "CLEAR_OFFLINE_MERCHANT_PRODUCT_DETAILS";
export const IS_OFFLINE_MERCHANT_PRODUCT_FILTER = "IS_OFFLINE_MERCHANT_PRODUCT_FILTER";
export const IS_OFFLINE_MERCHANT_PRODUCT_TRANSACTION_FILTER =
  "IS_OFFLINE_MERCHANT_PRODUCT_TRANSACTION_FILTER";
export const DOWNLOAD_WALLET_STATUS = "DOWNLOAD_WALLET_STATUS";
export const GET_OFFLINE_INCOMING_AMOUNT = "GET_OFFLINE_INCOMING_AMOUNT";
export const GET_MERCHANT_OFFLINE_CONFIG = "GET_MERCHANT_OFFLINE_CONFIG";

// paycodes
export const SAVE_PAYCODES = "SAVE_PAYCODES";
export const SAVE_BULK_PAYCODES = "SAVE_BULK_PAYCODES";
export const SAVE_PAYCODE_APPROVALS = "SAVE_PAYCODE_APPROVALS";
export const UPDATE_PAYCODE_APPROVAL_LOADING = "UPDATE_PAYCODE_APPROVAL_LOADING";
export const SAVE_PAYCODE_DETAILS = "SAVE_PAYCODE_DETAILS";
export const SAVE_BULK_PAYCODE_DETAILS = "SAVE_BULK_PAYCODE_DETAILS";
export const SAVE_PAYCODE_APPROVAL_DETAILS = "SAVE_PAYCODE_APPROVAL_DETAILS";
export const SAVE_PAYCODE_ACCOUNT_SUMMARY = "SAVE_PAYCODE_ACCOUNT_SUMMARY";
export const SAVE_BULK_PAYCODE_ACCOUNT_SUMMARY = "SAVE_BULK_PAYCODE_ACCOUNT_SUMMARY";
export const SAVE_PAYCODE_APPROVAL_ACCOUNT_SUMMARY = "SAVE_PAYCODE_APPROVAL_ACCOUNT_SUMMARY";
export const UPDATE_PAYCODE_LOADING = "UPDATE_PAYCODE_LOADING";
export const UPDATE_BULK_PAYCODE_LOADING = "UPDATE_BULK_PAYCODE_LOADING";
export const IS_PAYCODE_FILTER = "IS_PAYCODE_FILTER";
export const IS_PAYCODE_APPROVAL_FILTER = "IS_PAYCODE_APPROVAL_FILTER";

// refunds
export const SAVE_REFUNDS = "SAVE_REFUNDS";
export const SAVE_REFUND_DETAILS = "SAVE_REFUND_DETAILS";
export const SAVE_REFUND_ACCOUNT_SUMMARY = "SAVE_REFUND_ACCOUNT_SUMMARY";
export const SAVE_REFUND_ELIGIBLE_TRANSACTIONS = "SAVE_REFUND_ELIGIBLE_TRANSACTIONS";
export const CLEAR_REFUND_ELIGIBLE_TRANSACTIONS = "CLEAR_REFUND_ELIGIBLE_TRANSACTIONS";
export const UPDATE_REFUNDS_LOADING = "UPDATE_REFUNDS_LOADING";
export const SAVE_REJECTED_TRANSACTIONS = "SAVE_REJECTED_TRANSACTIONS";
export const SAVE_REJECTED_PAYMENT_DETAILS = "SAVE_REJECTED_PAYMENT_DETAILS";
export const UPDATE_REJECTED_LOADING = "UPDATE_REJECTED_LOADING";
export const SAVE_BULK_REJECTED_PAYMENTS_UPDATE_PROGRESS =
  "SAVE_BULK_REJECTED_PAYMENTS_UPDATE_PROGRESS";
export const SAVE_BULK_REFUND_UPDATE_PROGRESS = "SAVE_BULK_REFUND_UPDATE_PROGRESS,";
export const SAVE_REFUND_HISTORY = "SAVE_REFUND_HISTORY";
export const UPDATE_REFUND_HISTORY_LOADING = "UPDATE_REFUND_HISTORY_LOADING";

// App Info
export const SAVE_FO_APP_VERSION = "SAVE_FO_APP_VERSION";

export const SAVE_TRANSACTION_REFUNDS = "SAVE_TRANSACTION_REFUNDS";

export const RECEIPT_DOWNLOADED = "RECEIPT_DOWNLOADED";

export const SAVE_TRANSACTION_NOTIFICATION_REPORT = "SAVE_TRANSACTION_NOTIFICATION_REPORT";
export const SAVE_TRANSACTION_BENEFICIARIES = "SAVE_TRANSACTION_BENEFICIARIES";

// Payment links
export const SAVE_PAYMENT_PAGES_LIST = "SAVE_PAYMENT_PAGES_LIST";
export const SAVE_PAYMENT_PAGES_LIST_DETAILS = "SAVE_PAYMENT_PAGES_LIST_DETAILS";
export const UPDATE_PAYMENT_PAGE_LOADING = "UPDATE_PAYMENT_PAGE_LOADING";
export const CREATE_PAYMENT_PAGE = "CREATE_PAYMENT_PAGE";

// event logs
export const SAVE_EVENT_LOGS_FILTER_PARAMS = "SAVE_EVENT_LOGS_FILTER_PARAMS";
export const SAVE_EVENT_LOGS_AGGREGATE = "SAVE_EVENT_LOGS_AGGREGATE";

export const UPDATE_PAYMENT_PAGE_FORM_DATA = "UPDATE_PAYMENT_PAGE_FORM_DATA";

export const ADD_PAYMENT_PAGE_CUSTOM_ATTRIBUTES = "ADD_PAYMENT_PAGE_CUSTOM_ATTRIBUTES";

export const REMOVE_CUSTOM_ATTRIBUTE = "REMOVE_CUSTOM_ATTRIBUTE";

export const GET_ALL_TILLS = "GET_ALL_TILLS";
export const TILL_ACCOUNTS_LOADING = "TILL_ACCOUNTS_LOADING";
export const SAVE_TILL_DETAIL = "SAVE_TILL_DETAIL";

export const SAVE_TILL_TRANSACTIONS = "SAVE_TILL_TRANSACTIONS";
export const UPDATE_TILL_TRANSACTIONS_LOADING = "UPDATE_TILL_TRANSACTIONS_LOADING";
export const DEVICE_DETAIL_LOADING = "DEVICE_DETAIL_LOADING";
export const SAVE_DEVICE_DETAIL = "SAVE_DEVICE_DETAIL";

// workflow
export const SAVE_PENDING_WORKFLOW = "SAVE_PENDING_WORKFLOW";
export const SAVE_APPROVED_AND_REJECTED_WORKFLOW = "SAVE_APPROVED_AND_REJECTED_WORKFLOW";
export const SAVE_WORKFLOW_SUMMARY = "SAVE_WORKFLOW_SUMMARY";
export const WORKFLOW_LOADING = "WORKFLOW_LOADING";
export const SAVE_USER_HISTORY_WORKFLOW = "SAVE_USER_HISTORY_WORKFLOW";
export const SAVE_ALL_HISTORY_WORKFLOW = "SAVE_ALL_HISTORY_WORKFLOW";
export const SAVE_ALL_PENDING_WORKFLOW = "SAVE_ALL_PENDING_WORKFLOW";
export const SAVE_USER_PENDING_WORKFLOW = "SAVE_USER_PENDING_WORKFLOW";
export const SAVE_WORKFLOW_FILTER_PARAMS = "SAVE_WORKFLOW_FILTER_PARAMS";

export const SAVE_USER_DEVICES = "SAVE_USER_DEVICES";
export const SAVE_TILL_SUMMARY = "SAVE_TILL_SUMMARY";
export const SAVE_TILL_DEVICES = "SAVE_TILL_DEVICES";

export const UPDATE_PROVIDERS_LOADING = "UPDATE_PROVIDERS_LOADING";
export const UPDATE_PROVIDERS = "UPDATE_PROVIDERS";

export const ACCOUNT = "ACCOUNT";

export const SAVE_PAGE_TRANSACTIONS = "SAVE_PAGE_TRANSACTIONS";

export const SAVE_PAYMENT_PAGE_STATIC_SUMMARY = "SAVE_PAYMENT_PAGE_STATIC_SUMMARY";

export const SAVE_PAYMENT_PAGE_STATIC_SUMMARY_GRAPH = "SAVE_PAYMENT_PAGE_STATIC_SUMMARY_GRAPH";

export const SAVE_REFUND_BULK_UPLOAD_RESPONSE = "SAVE_REFUND_BULK_UPLOAD_RESPONSE";

export const SAVE_REJECTED_PAYMENT_SUMMARY = "SAVE_REJECTED_PAYMENT_SUMMARY";

export const CLEAR_PAYMENT_PAGE_FORM = "CLEAR_PAYMENT_PAGE_FORM";

export const SET_INITIAL_CUSTOM_ATTRIBUTE = "SET_INITIAL_CUSTOM_ATTRIBUTE";

// mfa
export const SAVE_MFA_DETAILS = "SAVE_MFA_DETAILS";
export const VERIFY_OTP = "VERIFY_OTP";
export const MFA_LOADING = "MFA_LOADING";
export const UPDATE_MFA_CONFIG = "UPDATE_MFA_CONFIG";
export const MFA_PREFERENCE_LOADING = "MFA_PREFERENCE_LOADING";
export const SAVE_CONTACT_DETAILS = "SAVE_CONTACT_DETAILS";
export const VERIFY_MOBILE_LOADING = "VERIFY_MOBILE_LOADING";
export const REQUEST_OTP_LOADING = "REQUEST_OTP_LOADING";

// wallet
export const LOADING_WALLET_TOPUP_ACCOUNTS = "LOADING_WALLET_TOPUP_ACCOUNTS";
export const SAVE_WALLET_TOPUP_ACCOUNTS = "SAVE_WALLET_TOPUP_ACCOUNTS";

export const LOADING_GET_ON_DEMAND_SETTLEMENT_COUNT = "LOADING_GET_ON_DEMAND_SETTLEMENT_COUNT";

export const SAVE_ON_DEMAND_SETTLEMENT_COUNT = "SAVE_ON_DEMAND_SETTLEMENT_COUNT";

export const PROCESS_ONDEMAND_SETTLEMENT = "PROCESS_ONDEMAND_SETTLEMENT";

export const LOADING_PROCESS_ONDEMAND_SETTLEMENT = "PROCESS_ONDEMAND_SETTLEMENT";

export const SAVE_PROCESS_ONDEMAND_SETTLEMENT_RESPONSE =
  "SAVE_PROCESS_ONDEMAND_SETTLEMENT_RESPONSE";
