export default {
  CAN_USE_PAYCODE_FEATURE: "PAYCODE",
  CAN_USE_OFFLINE_PAYMENT: "OFFLINE_PAYMENT",
  CAN_USE_REFUND: "REFUND",
  CAN_USE_RESEND_FAILED_EVENT_NOTIFICATIONS: "RESEND_FAILED_EVENT_NOTIFICATIONS",
  CAN_USE_DISBURSEMENT_UI: "DISBURSEMENT_UI",
  CAN_USE_DISBURSEMENT_API: "DISBURSEMENT_API",
  CAN_USE_TILL_FEATURE: "TILL",
  CAN_USE_PAYMENT_PAGE_FEATURE: "CAN_USE_PAYMENT_PAGE_FEATURE",
  CAN_USE_SUB_ACCOUNT: "SUB_ACCOUNT",
  CAN_USE_ONDEMAND_SETTLEMENT: "ONDEMAND_SETTLEMENT",
};
