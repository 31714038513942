import config from "../../../config";
import { onSuccessfulLogin, onLoginFailure, LOGIN_FORM } from "..";
import {
  SAVE_MFA_DETAILS,
  VERIFY_OTP,
  MFA_LOADING,
  UPDATE_MFA_CONFIG,
  MFA_PREFERENCE_LOADING,
  SAVE_CONTACT_DETAILS,
  VERIFY_MOBILE_LOADING,
  REQUEST_OTP_LOADING,
} from "../../types";
import api from "../../api";
import { extractErrors, extractRequestError, showAllErrors, showMessage } from "../../index";
import constant from "../../../constants";
import { push } from "react-router-redux";
import { routeToLogin } from "../../onboarding";
import { OTP_STATUS } from "../../../utils/otpUtils";
import dataStore from "../../../dataStore";
import { DATA_STORE_KEYS } from "../../../dataStore/keys";
import * as app from "../../../reducers/app";

const BASE_URL = `${config.USER_SERVICE_URL}/auth`;
export const MFA_INAPP_PATH = "/protect-your-account";
export const saveMfaDetails = (mfaDetails) => ({
  type: SAVE_MFA_DETAILS,
  payload: mfaDetails,
});

export const otpVerification = (data) => ({
  type: VERIFY_OTP,
  payload: data,
});

export const mfaLoading = (loading) => ({
  type: MFA_LOADING,
  payload: loading,
});

export const verifyMobileLoading = (loading) => ({
  type: VERIFY_MOBILE_LOADING,
  payload: loading,
});

export const updateMfaConfig = (mfaConfig) => ({
  type: UPDATE_MFA_CONFIG,
  payload: mfaConfig,
});

export const mfaPreferenceLoading = (loading) => ({
  type: MFA_PREFERENCE_LOADING,
  payload: loading,
});

export const saveContactDetails = (data) => ({
  type: SAVE_CONTACT_DETAILS,
  payload: data,
});

export const validateMfaDetails = (mfaDetails) => (dispatch) => {
  if (!hasAccessToken() && !mfaDetails.authCode) {
    dispatch(routeToLogin());
  }
};

export const requestOtpLoading = (loading) => ({
  type: REQUEST_OTP_LOADING,
  payload: loading,
});

export const hasAccessToken = () => !!dataStore.get(DATA_STORE_KEYS.ACCESS_TOKEN);

const getMfaFullPath = (apiPath) => {
  const isInApp = hasAccessToken();
  const prefix = isInApp ? "/2fa" : BASE_URL;
  return `${prefix}/${apiPath}`;
};

const handlePendingOtpStatus = (dispatch, data, onFinish, requeryAttemptsLeft) => {
  if (requeryAttemptsLeft > 0) {
    setTimeout(() => {
      dispatch(requeryOtpRequestStatus(onFinish, requeryAttemptsLeft - 1));
    }, constant.REQUERY_INTERVAL);
  } else {
    dispatch(otpVerification(data));
    dispatch(mfaLoading(false));
    dispatch(verifyMobileLoading(false));
    onFinish && onFinish(false);
  }
};

const handleFailedOtpStatus = (dispatch, onFinish, data, retryAttemptsLeft) => {
  dispatch(otpVerification(data));
  onFinish && onFinish(false);
  if (!retryAttemptsLeft) {
    dispatch(mfaLoading(false));
    dispatch(verifyMobileLoading(false));
  }
};

const handleSuccessOtpStatus = (dispatch, data, onFinish) => {
  dispatch(mfaLoading(false));
  dispatch(verifyMobileLoading(false));
  dispatch(otpVerification(data));
  if (data.otpStatus === OTP_STATUS.SENT || data.otpStatus === OTP_STATUS.EMAIL_SUCCESS) {
    onFinish && onFinish(true);
  } else {
    onFinish && onFinish(false);
  }
};

const handleOtpError = (dispatch, error, isRequest) => {
  const responseError = extractErrors(error)[0];
  const requestError = extractRequestError(error);
  if (isRequest) {
    dispatch(
      otpVerification({
        message: responseError || requestError,
        otpStatus: OTP_STATUS.ERROR,
      })
    );
  } else {
    if (requestError && !responseError) {
      showAllErrors(error);
    } else {
      dispatch(otpVerification({ otpError: responseError }));
    }
  }
};

export const handleOtpResponse =
  (response, onFinish, requeryAttemptsLeft, retryAttemptsLeft) => (dispatch) => {
    const data = response.data?.responseBody || {};
    switch (data.otpStatus) {
      case OTP_STATUS.PENDING:
        handlePendingOtpStatus(dispatch, data, onFinish, requeryAttemptsLeft);
        break;
      case OTP_STATUS.FAILED:
      case OTP_STATUS.ERROR:
        handleFailedOtpStatus(dispatch, onFinish, data, retryAttemptsLeft);
        break;
      default:
        handleSuccessOtpStatus(dispatch, data, onFinish);
        break;
    }
  };

export const goToVerifyOtpPage = () => (dispatch) => {
  dispatch(push("/verify-otp"));
};

export const goToMfaPreferenceSetupPage = () => (dispatch) => {
  dispatch(push("/mfa-preference-setup"));
};

export const goToVerifyMobilePage = () => (dispatch) => {
  dispatch(push("/verify-mobile"));
};

export const goToMfaSetupCompletePage = () => (dispatch) => {
  dispatch(push("/mfa-setup-complete"));
};

export const goToMfaSetupPage = () => (dispatch) => {
  dispatch(push("/mfa-setup"));
};

export const goToInAppMfaSetupPage = () => (dispatch) => {
  dispatch(push(MFA_INAPP_PATH));
};

export const goToUpdateMobilePage = () => (dispatch) => {
  dispatch(push("/update-mobile"));
};

export const mfaLogin = (data, intent) => (dispatch, getState) => {
  const url = `${BASE_URL}/2fa-login`;
  const skipMfaCheckWithAccessToken = !data?.otp;
  dispatch(mfaLoading(true));
  dispatch(otpVerification({}));
  dispatch(
    api.post(
      url,
      data,
      (response) =>
        onSuccessfulLogin(response, dispatch, getState, { skipMfaCheckWithAccessToken, intent }),
      (error) => {
        dispatch(mfaLoading(false));
        onLoginFailure(error, dispatch);
        handleOtpError(dispatch, error);
      }
    )
  );
};

export const verifyMobile =
  (onFinish, retryAttemptsLeft = constant.RETRY_ATTEMPT_COUNT) =>
  (dispatch) => {
    const requeryAttempts = constant.RETRY_ATTEMPT_COUNT;
    const url = getMfaFullPath("verify/mobile");
    dispatch(verifyMobileLoading(true));
    dispatch(
      api.post(
        url,
        {},
        (response) => {
          dispatch(handleOtpResponse(response, onFinish, requeryAttempts, retryAttemptsLeft));
        },
        (error) => {
          handleOtpError(dispatch, error, true);
          dispatch(verifyMobileLoading(false));
          onFinish && onFinish(false);
        }
      )
    );
  };

export const requeryOtpRequestStatus =
  (onFinish, requeryAttemptsLeft = 0) =>
  (dispatch) => {
    const url = getMfaFullPath("otp/requery");

    dispatch(
      api.get(
        url,
        {},
        (response) => {
          dispatch(handleOtpResponse(response, onFinish, requeryAttemptsLeft));
        },
        (error) => {
          handleOtpError(dispatch, error, true);
          dispatch(mfaLoading(false));
          onFinish && onFinish(false);
        }
      )
    );
  };

export const resendOtp =
  (onFinish, retryAttemptsLeft = constant.RETRY_ATTEMPT_COUNT) =>
  (dispatch) => {
    const url = getMfaFullPath("otp/resend");
    dispatch(
      api.post(
        url,
        {},
        (response) => {
          const onComplete = (isSuccess) => {
            if (isSuccess) {
              showMessage("OTP", "Your OTP has been sent!", "success");
            }
            onFinish && onFinish(isSuccess);
          };
          dispatch(handleOtpResponse(response, onComplete, retryAttemptsLeft));
        },
        (error) => {
          dispatch(otpVerification({ otpStatus: OTP_STATUS.ERROR }));
          onFinish && onFinish(false);
          showAllErrors(error);
        }
      )
    );
  };

export const resendOtpViaAlternateOption =
  ({ otpDeliveryType }, onFinish) =>
  (dispatch) => {
    dispatch(requestOtp(onFinish, otpDeliveryType));
  };

export const handleUpdatePreference = (data, onFinish) => (dispatch) => {
  const url = getMfaFullPath("default-preference");
  dispatch(
    api.post(
      url,
      data,
      () => {
        onFinish && onFinish(true);
        showMessage("OTP Preference", "Your OTP preference has been updated!", "success");
      },
      (error) => {
        onFinish && onFinish(false);
        showAllErrors(error);
      }
    )
  );
};

export const saveMfaPreference =
  (data, onFinish, hasSubsequentRequest = false) =>
  (dispatch) => {
    const url = hasAccessToken()
      ? `${config.USER_SERVICE_URL}/2fa-preferences`
      : `${BASE_URL}/2fa/preference`;

    dispatch(mfaPreferenceLoading(true));
    dispatch(
      api.post(
        url,
        data,
        (response) => {
          const data = response.data?.responseBody || {};
          dispatch(updateMfaConfig(data));
          if (!hasSubsequentRequest) {
            dispatch(mfaPreferenceLoading(false));
          }

          dataStore.save(DATA_STORE_KEYS.USER_MFA_CONFIG, data);
          onFinish && onFinish(true);
        },
        (error) => {
          onFinish && onFinish(false);
          dispatch(mfaPreferenceLoading(false));
          showAllErrors(error);
        }
      )
    );
  };

export const verifyOtp = (data, onFinish) => (dispatch) => {
  const url = `/2fa/otp/validate`;
  dispatch(otpVerification({}));
  dispatch(
    api.post(
      url,
      data,
      (response) => {
        const { phoneVerified, mfaComplete } = response.data?.responseBody || {};
        const user = dataStore.get(DATA_STORE_KEYS.USER_DATA);
        const mfaConfig = dataStore.get(DATA_STORE_KEYS.USER_MFA_CONFIG);
        const updatedUser = { ...user, phoneVerified };
        const updatedMfaConfig = { ...mfaConfig, mfaComplete };
        dataStore.save(DATA_STORE_KEYS.USER_DATA, updatedUser);
        dataStore.save(DATA_STORE_KEYS.USER_MFA_CONFIG, updatedMfaConfig);
        dispatch(updateMfaConfig({ mfaComplete }));
        onFinish && onFinish(true);
      },
      (error) => {
        onFinish && onFinish(false);
        handleOtpError(dispatch, error);
      }
    )
  );
};

export const requestOtp = (onFinish, preferredOtpDeliveryType) => (dispatch) => {
  let url = `${BASE_URL}/otp/send`;
  if (preferredOtpDeliveryType) {
    url = `${url}?deliveryType=${preferredOtpDeliveryType}`;
  }

  dispatch(
    api.post(
      url,
      {},
      (response) => {
        dispatch(handleOtpResponse(response, onFinish, constant.RETRY_ATTEMPT_COUNT));
      },
      (error) => {
        onFinish && onFinish(false);
        handleOtpError(dispatch, error);
      }
    )
  );
};

export const requestOtpOn2faSubsequentLogin = () => (dispatch) => {
  dispatch(requestOtpLoading(true));
  dispatch(mfaPreferenceLoading(true));
  const onFinish = () => {
    dispatch(goToVerifyOtpPage());
    dispatch(app.deleteSubmitForm(LOGIN_FORM));
    dispatch(requestOtpLoading(false));
    dispatch(mfaPreferenceLoading(false));
  };
  dispatch(requestOtp(onFinish));
};

export const updateMobile = (data, onFinish) => (dispatch) => {
  const url = getMfaFullPath("update/mobile");
  dispatch(
    api.put(
      url,
      data,
      () => {
        onFinish && onFinish(true);
      },
      (error) => {
        showAllErrors(error);
        onFinish && onFinish(false);
      }
    )
  );
};
