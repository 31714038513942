import api from "./api";
import { push } from "react-router-redux";
import { showError, showMessage } from "./index";
import {
  ACTIVE_BUSINESS_SET,
  UPDATE_SWITCH_BUSINESS_LOADING,
  CREATE_BUSINESS_LOADING,
  SHOW_SELECT_BUSINESS_MODAL,
  TOGGLE_SHOW_SELECT_BUSINESS_MODAL,
  UPDATE_BUSINESS_DETAILS_LOADING,
  SAVE_BUSINESS_DETAILS,
  SET_KYC,
  TOGGLE_BUSINESS_REFRESH,
  SAVE_BUSINESS_LOGO,
  UPDATE_BUSINESS_LOGO_LOADING,
  GET_BUSINESS_DETAILS_LOADING,
  CLEAR_BUSINESS_LOGO,
  PURGE_STATE,
} from "../actions/types";
import { saveUserAuthorities } from "./onboarding";
import { initEnvironmentAwareNew } from "../../modules/util/EnvironmentAware/action";
import { DATA_STORE_KEYS } from "../dataStore/keys";
import DataStore from "../dataStore";
import ReactDOM from "react-dom";
import { DEFAULT_PRE_VERIFIED_HOME_ROUTE } from "../utils";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "../constants/mixpanel";

const BUSINESS_BASE_URL = "/business";

const updateCreateBusinessLoading = (payload) => ({
  type: CREATE_BUSINESS_LOADING,
  payload,
});
const updateBusinessDetailsLoading = (payload) => ({
  type: UPDATE_BUSINESS_DETAILS_LOADING,
  payload,
});
const getBusinessDetailsLoading = (payload) => ({
  type: GET_BUSINESS_DETAILS_LOADING,
  payload,
});
const updateSwitchBusinessLoading = (payload) => ({
  type: UPDATE_SWITCH_BUSINESS_LOADING,
  payload,
});

export const saveBusinessDetails = (payload) => ({
  type: SAVE_BUSINESS_DETAILS,
  payload,
});

export const saveBusinessLogo = (payload) => ({
  type: SAVE_BUSINESS_LOGO,
  payload,
});
export const updateBusinessLogoLoading = (payload) => ({
  type: UPDATE_BUSINESS_LOGO_LOADING,
  payload,
});

export const moveToDashboard = (dispatch) => {
  dispatch({ type: ACTIVE_BUSINESS_SET });

  dispatch(push("/dashboard"));
};

export const moveToAddBusinessPage = () => (dispatch) => {
  dispatch(push("/add-business"));
};

// switchBusy = function(args){
//   return function(dispatch){

//   }
// }

export const switchBusiness = (request, onSuccess, onFail) => (dispatch, getState) => {
  switchBusinessDispatch({ request, onSuccess, onFail }, dispatch, getState);
};

export const switchBusinessDispatch = ({ request, onSuccess, onFail }, dispatch, getState) => {
  const requestURL = `auth/select/${request.merchantCode}`;

  const successHandler = (response) => {
    const { merchantKycStatus, businessType, selectedMerchant, merchantList, authorities } =
      response.data.responseBody;
    const { merchantCode, merchantBusinessName, merchantEmail, merchantAllowedFeatures } =
      selectedMerchant;

    // eslint-disable-next-line react/no-deprecated
    ReactDOM.unmountComponentAtNode(document.querySelector(".monnify-main-content-wrap"));
    dispatch({ type: PURGE_STATE });

    dispatch(updateSwitchBusinessLoading(false));
    showMessage("Switch business", `Switched to ${merchantBusinessName}`);

    saveBusinessInfo({
      kycStatus: merchantKycStatus,
      businessType,
      businessName: merchantBusinessName,
      merchantEmail,
      merchantCode,
      merchantList,
      authorities,
      merchantAllowedFeatures,
    });

    dispatch({ type: SET_KYC, payload: merchantKycStatus });

    const viewDashboard = "MFO_VIEW_DASHBOARD";
    const viewTransactions = "MFO_VIEW_TRANSACTIONS";
    const viewReservedAccounts = "MFO_VIEW_RESERVED_ACCOUNTS";
    const viewSubAccounts = "MFO_VIEW_SUB_ACCOUNTS";
    const viewSettlement = "MFO_VIEW_SETTLEMENTS";
    const viewDisbursement = "MFO_VIEW_DISBURSEMENT_FEATURE";
    const viewTills = "MFO_VIEW_TILL_ACCOUNTS";

    const canViewDashboard = authorities?.some((role) => role === viewDashboard);
    const canViewTransactions = authorities?.some((role) => role === viewTransactions);
    const canViewReservedAccounts = authorities?.some((role) => role === viewReservedAccounts);
    const canViewSubAccounts = authorities?.some((role) => role === viewSubAccounts);
    const canViewSettlement = authorities?.some((role) => role === viewSettlement);
    const canViewDisbursement = authorities?.some((role) => role === viewDisbursement);
    const canViewTills = authorities?.some((role) => role === viewTills);

    mixpanel.track(MIXPANEL_EVENTS.SWITCH_BUSINESS, {
      merchant: merchantBusinessName,
    });
    mixpanel.register({
      merchant: merchantBusinessName,
    });

    onSuccess && onSuccess();
    if (merchantKycStatus === "PENDING_BUSINESS_TYPE") {
      // dispatch(push("/onboard"));
    } else if (merchantKycStatus === "PENDING_KYC_VERIFICATION") {
      dispatch(push(DEFAULT_PRE_VERIFIED_HOME_ROUTE));
    } else if (canViewDashboard) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/dashboard"));
    } else if (canViewTransactions) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/transactions"));
    } else if (canViewReservedAccounts) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/reserved-accounts"));
    } else if (canViewSubAccounts) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/sub-accounts"));
    } else if (canViewSettlement) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/settlements"));
    } else if (canViewDisbursement) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/transfers"));
    } else if (canViewTills) {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/tills"));
    } else {
      dispatch({ type: TOGGLE_BUSINESS_REFRESH });
      dispatch(push("/settings"));
    }

    dispatch({ type: CLEAR_BUSINESS_LOGO });
    dispatch(saveUserAuthorities({ authorities }));
    initEnvironmentAwareNew(dispatch, getState);
  };

  const errorHandler = (error) => {
    onFail && onFail();
    dispatch(updateSwitchBusinessLoading(false));
    showError(error);
  };

  dispatch(updateSwitchBusinessLoading(true));
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const checkMerchantBusinessOnReload = () => (dispatch) => {
  const merchantBusinesses = DataStore.get(DATA_STORE_KEYS.MERCHANT_BUSINESSES);
  if (
    !DataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS) &&
    merchantBusinesses &&
    merchantBusinesses.length > 0
  ) {
    dispatch({ type: SHOW_SELECT_BUSINESS_MODAL });
  }
};

export const toggleShowSelectBusinessModal = () => (dispatch) => {
  if (DataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS)) {
    dispatch({
      type: TOGGLE_SHOW_SELECT_BUSINESS_MODAL,
    });
  }
};

const saveBusinessInfo = (data) => {
  const {
    kycStatus,
    businessType,
    businessName,
    merchantCode,
    merchantList,
    authorities,
    merchantAllowedFeatures,
    merchantEmail,
  } = data;
  const existingBusinesses = DataStore.get(DATA_STORE_KEYS.MERCHANT_BUSINESSES);
  const newBusiness = {
    businessName,
    merchantCode,
    merchantAllowedFeatures,
    merchantEmail,
  };
  const saveMerchantList = merchantList || [...existingBusinesses, newBusiness];

  DataStore.save(DATA_STORE_KEYS.ACTIVE_BUSINESS, newBusiness);
  DataStore.save(DATA_STORE_KEYS.KYC_STATUS, kycStatus);
  DataStore.save(DATA_STORE_KEYS.BUSINESS_TYPE, businessType);
  // DataStore.save(DATA_STORE_KEYS.USER_DATA, data);
  DataStore.save(DATA_STORE_KEYS.MERCHANT_BUSINESSES, saveMerchantList);
  DataStore.save(DATA_STORE_KEYS.AUTHORITIES, authorities);
};

export const createBusiness = (request) => (dispatch, getState) => {
  const requestURL = `${BUSINESS_BASE_URL}/create`;
  const successHandler = (response) => {
    showMessage("Create new business", `${request.name} has been created.`);
    switchBusinessDispatch(
      {
        request: response.data.responseBody,
        onSuccess: () => {
          dispatch(updateCreateBusinessLoading(false));
        },
        onFail: () => {
          dispatch(updateCreateBusinessLoading(false));
        },
      },
      dispatch,
      getState
    );
    // switchBusiness(response.data.responseBody, null, null, dispatch, getState);

    // dispatch(push("/activate-business"));
  };

  const errorHandler = (error) => {
    dispatch(updateCreateBusinessLoading(false));
    showError(error);
  };

  dispatch(updateCreateBusinessLoading(true));
  dispatch(api.post(requestURL, request, successHandler, errorHandler));
};

export const setDefaultBusiness = (code) => (dispatch) => {
  const requestURL = `${BUSINESS_BASE_URL}/set-default/${code}`;
  const successHandler = (response) => {
    showMessage("Set business as default", `Businesss has been set as default.`);
  };

  const errorHandler = (error) => {
    showError(error);
  };

  dispatch(api.post(requestURL, {}, successHandler, errorHandler));
};

export const getBusinessDetails = () => (dispatch, getState) => {
  const business = DataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS);

  if (!business) return;

  const requestURL = `${BUSINESS_BASE_URL}/${business.merchantCode}`;

  const successHandler = (response) => {
    dispatch(saveBusinessDetails(response.data.responseBody));
    dispatch(getBusinessDetailsLoading(false));
  };

  const errorHandler = (error) => {
    showError(error);
    dispatch(getBusinessDetailsLoading(false));
  };

  dispatch(getBusinessDetailsLoading(true));
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};

export const updateBusinessDetails = (request) => (dispatch, getState) => {
  const business = DataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS);

  const {
    businessCategoryName,
    businessDescription,
    supportEmail,
    chargeBackEmail,
    address,
    website,
    facebookUsername,
    twitterUsername,
    linkedinUsername,
    instagramUsername,
    file,
    logo,
    lgaCode,
    businessName,
    email,
    phoneNumber,
    sendEmailToCustomer,
  } = request;

  const formData = new FormData();

  businessName && formData.append("businessName", businessName);
  sendEmailToCustomer && formData.append("sendEmailToCustomer", sendEmailToCustomer);
  email && formData.append("email", email);
  phoneNumber && formData.append("phoneNumber", phoneNumber);
  businessCategoryName && formData.append("businessCategoryName", businessCategoryName);
  businessDescription && formData.append("businessDescription", businessDescription);
  supportEmail && formData.append("supportEmail", supportEmail);
  chargeBackEmail && formData.append("chargeBackEmail", chargeBackEmail.trim());
  address && formData.append("address", address);
  lgaCode && formData.append("lgaCode", lgaCode);

  if (logo) {
    formData.append("logoFileType", file.extension);
    formData.append("logo", logo);
  }
  if (website) {
    formData.append("website", website);
  }
  if (facebookUsername) {
    formData.append("facebookUsername", facebookUsername);
  }
  if (twitterUsername) {
    formData.append("twitterUsername", twitterUsername);
  }
  if (instagramUsername) {
    formData.append("instagramUsername", instagramUsername);
  }
  if (linkedinUsername) {
    formData.append("linkedinUsername", linkedinUsername);
  }

  const requestURL = `${BUSINESS_BASE_URL}/update/${business.merchantCode}`;

  const successHandler = (response) => {
    showMessage("Update business details", `Business detail has been updated.`);
    dispatch(updateBusinessDetailsLoading(false));
  };

  const errorHandler = (error) => {
    showError(error);
    dispatch(updateBusinessDetailsLoading(false));
  };

  dispatch(updateBusinessDetailsLoading(true));
  dispatch(api.put(requestURL, formData, successHandler, errorHandler));
};

export const getBusinessLogo = () => (dispatch) => {
  const requestURL = `${BUSINESS_BASE_URL}/get-business-logo`;

  const successHandler = (response) => {
    dispatch(saveBusinessLogo(response.data.responseBody));
    dispatch(updateBusinessLogoLoading(false));
  };

  const errorHandler = (error) => {
    // showError(error);
    dispatch({ type: CLEAR_BUSINESS_LOGO });
    dispatch(updateBusinessLogoLoading(false));
  };

  dispatch(updateBusinessLogoLoading(true));
  dispatch(api.get(requestURL, {}, successHandler, errorHandler));
};
