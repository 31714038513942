import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetHideLogin, setLoading, setUpdatingContent } from "./../../../reducers/app";
import { initAuth, logout } from "../../../actions/auth";
import axios from "axios";
import NotFoundPage from "./../../../../modules/error-pages/NotFoundPage";
import IdleTimer from "react-idle-timer";
import Constants from "../../../constants";
import dataStore from "../../../dataStore";
import { DATA_STORE_KEYS } from "../../../dataStore/keys";
import { saveVisitedRoutes } from "../../../actions";
import { customerIO } from "../../CustomerIO";

let source = null;

const mapStateToProps = (state) => ({
  isLoading: state.app.isLoading,
});

@connect(mapStateToProps)
class Page extends React.Component {
  static propTypes = {
    roles: PropTypes.array,
    pathName: PropTypes.any,
    isNotFound: PropTypes.bool,
    onMounted: PropTypes.func,
    children: PropTypes.any,
    breadcrumb: PropTypes.array,
    containerExtraClass: PropTypes.string,
    location: PropTypes.object,
    history: PropTypes.object,
    // from connect
    isLoading: PropTypes.bool,
    dispatch: PropTypes.func,
    loginRequired: PropTypes.bool,
  };

  static contextTypes = {
    setContentBuffer: PropTypes.func,
  };

  static defaultProps = {
    roles: [],
    pathName: null,
    isNotFound: false,
    onMounted: null,
    isLoading: false,
    loginRequired: true,
    dispatch: () => {},
  };

  isFirstContent = true;
  _onMounted = null;
  timeoutId = null;
  isStartLoading = false;
  source = null;

  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  UNSAFE_componentWillMount() {
    const businessType = dataStore.get(DATA_STORE_KEYS.KYC_STATUS);
    const { pathname } = window.location;
    if (businessType === "PENDING_BUSINESS_TYPE" && pathname.includes("/verify-email") === false) {
      // this.props.dispatch(push('/onboard'));
    }
  }

  componentDidMount() {
    this.trackPageForCustomerIo();

    if (source) {
      source.cancel();
    }

    // FIXME https://github.com/axios/axios/issues/978
    source = axios.CancelToken.source();
    // eslint-disable-next-line no-self-assign
    source.token.throwIfRequested = source.token.throwIfRequested;
    source.token.promise.then = source.token.promise.then.bind(source.token.promise);
    source.token.promise.catch = source.token.promise.catch.bind(source.token.promise);
    axios.defaults.cancelToken = source.token;

    const { onMounted, roles, dispatch, loginRequired } = this.props;
    if (roles.length > -1 && loginRequired) {
      this._onMounted = () =>
        dispatch(initAuth(roles)).then((response) => {
          if (response && onMounted) {
            return onMounted();
          }
        });
    } else {
      this._onMounted = onMounted;
    }

    if (!this._onMounted) {
      this.updateContent();
      return;
    }

    dispatch(setLoading(true));
    this.isStartLoading = true;
    let isResolve = false;

    this.timeoutId = setTimeout(() => {
      this.timeoutId = null;
      if (isResolve) {
        this.stopLoading();
      }
    }, 0); // show state.app.isLoading equal or more then 0ms

    if (this._onMounted) {
      this._onMounted()
        .catch(() => {})
        .then(() => {
          isResolve = true;
          if (!this.timeoutId) {
            this.stopLoading();
          }
        });
    }

    this.getVisitedPage();
    this.sendPageView();
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.stopLoading();
  }

  componentDidUpdate() {
    const { isLoading } = this.props;
    if (this._onMounted && !isLoading) {
      this.updateContent();
    }
  }

  updateContent = () => {
    const { setContentBuffer } = this.context;
    const { isNotFound, pathName, children, dispatch, breadcrumb, containerExtraClass } =
      this.props;

    setContentBuffer({
      pathName,
      content: isNotFound ? <NotFoundPage /> : children,
      breadcrumb,
      containerExtraClass,
    });
    dispatch(setUpdatingContent(true));
    if (this.isFirstContent) {
      this.isFirstContent = false;
      setTimeout(() => window.scrollTo(0, 0));
    }
  };

  _onAction() {}

  _onActive() {}

  _onIdle() {
    const { dispatch, location } = this.props;
    const pathname = (location && location.pathname) || "";

    if (pathname !== "/login") {
      dispatch(logout());
    }
  }

  stopLoading = () => {
    if (this.isStartLoading) {
      this.isStartLoading = false;
      const { dispatch } = this.props;
      setTimeout(() => {
        dispatch(setLoading(false));
        dispatch(resetHideLogin());
      });
    }
  };

  getVisitedPage = () => {
    const { history } = this.props;
    if (history && history.location.pathname !== "/login") {
      saveVisitedRoutes(history.location.pathname);
    }
  };
  sendPageView = () => {
    const authorization = dataStore.get(DATA_STORE_KEYS.ACCESS_TOKEN);
    if (authorization) {
      const _sift = (window._sift = window._sift || []);
      _sift.push(["_trackPageview"]);
    }
  };

  trackPageForCustomerIo() {
    customerIO.page();
  }

  render() {
    return (
      <IdleTimer
        ref={(ref) => {
          this.idleTimer = ref;
        }}
        element={document}
        onActive={this.onActive}
        onIdle={this.onIdle}
        onAction={this.onAction}
        debounce={250}
        timeout={1000 * 60 * Constants.IDLE_TIMEOUT_IN_MINUTES}
      />
    );
  }
}

export default Page;
