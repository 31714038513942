import {
  SAVE_DASHBOARD_GRAPH_DATA,
  SAVE_DASHBOARD_STATIC_DATA,
  UPDATE_DASHBOARD_GRAPH_LOADING,
  UPDATE_DASHBOARD_STATIC_LOADING,
  SAVE_CURRENT_FILTER,
  LOADING_GET_ON_DEMAND_SETTLEMENT_COUNT,
  LOADING_PROCESS_ONDEMAND_SETTLEMENT,
  SAVE_ON_DEMAND_SETTLEMENT_COUNT,
  SAVE_PROCESS_ONDEMAND_SETTLEMENT_RESPONSE,
} from "./../actions/types";

import _ from "lodash";

const INIT_STATE = {
  data: {
    live: null,
    test: null,
  },
  loading: false,
  staticData: {
    test: false,
    live: false,
  },
  currentFilter: "TODAY",
  getSettlementCountLoading: false,
  processOnDemandSettlementLoading: false,
  onDemandSettlmentCount: {
    requestCount: 0,
    allowedRequestCount: 0,
    amountAvailableForSettlement: 0,
    minimumSettlementAmount: 0,
  },
  onDemandSettlementResponse: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_CURRENT_FILTER:
      return { ...state, currentFilter: action.payload };
    case SAVE_DASHBOARD_GRAPH_DATA: {
      const recentDashboardData = _.cloneDeep(state.data);
      recentDashboardData[action.payload.environment] = action.payload.data;
      return {
        ...state,
        data: recentDashboardData,
      };
    }

    case SAVE_DASHBOARD_STATIC_DATA: {
      const recentDashboardStaticData = _.cloneDeep(state.staticData);
      recentDashboardStaticData[action.payload.environment] = action.payload.data;
      return {
        ...state,
        staticData: recentDashboardStaticData,
      };
    }

    case UPDATE_DASHBOARD_GRAPH_LOADING:
      return {
        ...state,
        loading: !!action.payload,
      };
    case UPDATE_DASHBOARD_STATIC_LOADING:
      return {
        ...state,
        staticLoading: !!action.payload,
      };

    case LOADING_GET_ON_DEMAND_SETTLEMENT_COUNT:
      return {
        ...state,
        getSettlementCountLoading: action.payload,
      };

    case LOADING_PROCESS_ONDEMAND_SETTLEMENT:
      return {
        ...state,
        processOnDemandSettlementLoading: action.payload,
      };

    case SAVE_ON_DEMAND_SETTLEMENT_COUNT:
      return {
        ...state,
        onDemandSettlmentCount: {
          ...action.payload,
        },
      };

    case SAVE_PROCESS_ONDEMAND_SETTLEMENT_RESPONSE:
      return {
        ...state,
        onDemandSettlementResponse: action.payload,
      };

    default:
      return state;
  }
};
