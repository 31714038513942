/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from "react";
import {
  resendVerificationEmail,
  checkEmailIsVerified,
} from "../../../../common/actions/onboarding";
import styled from "styled-components";
import { connect } from "react-redux";
import { Spin } from "antd";
import dataStore from "../../../../common/dataStore";
import { DATA_STORE_KEYS } from "../../../../common/dataStore/keys";
import MonnifyIcon from "../../../icons";
import "./styles.scss";
import propTypes from "prop-types";

const mapStateToProps = (state) => {
  const switchBusinessLoading = state.onboarding.loading;
  return { switchBusinessLoading };
};

@connect(mapStateToProps, {
  resendVerificationEmail,
  checkEmailIsVerified,
})
class EmailVerificationBanner extends Component {
  state = {
    loading: false,
    email: null,
    isVisible: false,
  };

  loadData = () => {
    const email =
      dataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS) &&
      dataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS).merchantEmail;
    this.props.checkEmailIsVerified(({ emailVerified }) => {
      if (emailVerified) {
        this.setState({ isVisible: false });
      } else {
        this.setState({ isVisible: true });
      }
    });
    this.setState({ email });
  };

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { switchBusinessLoading } = this.props;
    if (prevProps.switchBusinessLoading !== switchBusinessLoading) {
      this.loadData();
    }
  }

  handleVerificationClick = () => {
    this.setState({ loading: true });
    this.props.resendVerificationEmail(() => {
      this.setState({ loading: false });
    });
  };
  render() {
    return (
      <EmailVerificationStyle
        className={`mf-secondary-bg-l email-verification-banner ${!this.state.isVisible && "hide"}`}
      >
        <Spin spinning={this.state.loading}>
          <div className="email-verif-container">
            <MonnifyIcon style={{ marginRight: 15 }} type="INFO_ICON_ORANGE" />
            <span>
              Please click the link sent to{" "}
              <span className="mf-secondary-color">{this.state.email}</span> to verify your email.
              {`Didn't get the email?`}
              <span
                className="mf-secondary-color verify-email-link"
                onClick={this.handleVerificationClick}
              >
                Click here to resend
              </span>
            </span>
          </div>
        </Spin>
      </EmailVerificationStyle>
    );
  }
}

EmailVerificationBanner.propTypes = {
  switchBusinessLoading: propTypes.bool,
  resendVerificationEmail: propTypes.func,
  checkEmailIsVerified: propTypes.func,
};

const EmailVerificationStyle = styled.main`
  text-align: center;
  padding: 13px 0;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: -4.5rem;
  left: 50%;
  transform: translateX(-50%);

  &.hide {
    display: none;
  }

  .verify-email-link {
    text-decoration: underline;
    cursor: pointer;
  }

  .verify-email-link:active {
    text-decoration: none;
  }
`;

export default EmailVerificationBanner;
