import React from "react";
import { connect } from "react-redux";
import { Icon, Menu, Dropdown, Badge, Button, Select, Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import "./style.scss";
import { logout, setLayoutStateAction } from "../../../../common/actions/auth";
import EnvironmentSwitch from "../../EnvironmentSwitch";
import MonnifyIcon from "./../../../icons";
import dataStore from "../../../dataStore";
import { DATA_STORE_KEYS } from "../../../dataStore/keys";
import Media from "react-media";
import { toggleShowSelectBusinessModal, moveToAddBusinessPage } from "../../../actions/businesses";
import { moveToOnboard } from "../../../actions/onboarding";
import mixpanel from "mixpanel-browser";
import PropTypes from "prop-types";
import { MIXPANEL_EVENTS } from "../../../constants/mixpanel";

const { Option } = Select;
const { Text } = Typography;

const mapStateToProps = (state, props) => {
  const { onboarding, app } = state;
  const { layoutState } = app;
  const showEnvironmentSwitch =
    onboarding &&
    (onboarding.merchantKycStatus === "COMPLETED" || onboarding.merchantKycStatus === "LEGACY");

  return {
    collapsed: !!layoutState.menuOpened,
    onboardingData: onboarding || {},
    showEnvironmentSwitch,
    kycStatusCompleted: onboarding.kycStatusCompleted,
  };
};

@connect(mapStateToProps, {
  toggleShowSelectBusinessModal,
  moveToAddBusinessPage,
  logout,
  moveToOnboard,
  setLayoutStateAction,
})
class TopBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      kycStatus: dataStore.get(DATA_STORE_KEYS.KYC_STATUS),
    };

    this.PENDING_BUSINESS_TYPE = "PENDING_BUSINESS_TYPE";

    this.userMenu = (
      <Menu className="user-menu-dropdown">
        <Menu.Item>
          <Link to="/settings">Change Password</Link>
        </Menu.Item>

        <Menu.Item>
          <Link onClick={this.logout} to="#">
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    );

    this.logoutOnlyMenu = (
      <Menu style={{ display: "block" }} className="user-menu-dropdown">
        <Menu.Item>
          <Link onClick={this.logout} to="#">
            &nbsp;&nbsp;Logout&nbsp;&nbsp;
          </Link>
        </Menu.Item>
      </Menu>
    );
  }

  UNSAFE_componentWillMount() {
    this.setState({ kycStatus: dataStore.get(DATA_STORE_KEYS.KYC_STATUS) });
  }

  renderSwitchBussiness = () => (
    <Menu style={{ padding: "15px 12px 15px" }} className="switch-bussiness-menu-dropdown">
      <Menu.Item onClick={this.props.toggleShowSelectBusinessModal}>
        <MonnifyIcon type="SWITCH_BUSINESSING" />{" "}
        <span className="menu-dropdown-text">Switch business</span>
      </Menu.Item>
      <div />
      <Menu.Item onClick={this.props.moveToAddBusinessPage}>
        <MonnifyIcon type="ADD_BUSINESS" /> <span className="menu-dropdown-text">Add business</span>
      </Menu.Item>
    </Menu>
  );

  renderProfileMenu = () => (
    <Menu style={{ padding: "15px 12px 15px" }} className="user-menu-dropdown">
      {this.props.onboardingData.merchantKycStatus !== this.PENDING_BUSINESS_TYPE && (
        <Menu.Item>
          <Link to="/settings">Change Password</Link>
        </Menu.Item>
      )}
    </Menu>
  );

  renderUserMobileMenu = () => (
    <Menu style={{ padding: "15px 12px 15px" }} className="user-menu-dropdown">
      {this.props.showEnvironmentSwitch === true ? (
        <EnvironmentSwitch style={{ margin: 0, padding: "8px 10px" }} />
      ) : (
        <ActivationButton
          style={{
            padding: "15px 10px",
            marginTop: "8px",
            marginLeft: "5px",
          }}
          onClick={this.props.moveToOnboard}
        >
          Activate Your Business
        </ActivationButton>
      )}

      {this.props.onboardingData.merchantKycStatus !== this.PENDING_BUSINESS_TYPE && (
        <Menu.Item>
          <Link
            style={{
              paddingLeft: "5px",
              paddingTop: "10px",
              fontWeight: "bold",
            }}
            to="/settings"
          >
            Change Password
          </Link>
        </Menu.Item>
      )}
      <Menu.Item>
        <Tooltip title="Logout">
          <Link
            style={{
              paddingLeft: "5px",
              paddingTop: "10px",
              fontWeight: "bold",
            }}
            onClick={this.logout}
            to="#"
          >
            Logout
          </Link>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );

  logout = () => {
    this.props.logout();
    // your user logs out and tracks a log out event
    mixpanel.track(MIXPANEL_EVENTS.LOGOUT);

    // generate new cookie with new distinct_id
    mixpanel.reset();
  };

  toggleCollapsed = () => {
    this.props.setLayoutStateAction({
      menuCollapsed: this.props.collapsed,
      menuOpened: !this.props.collapsed,
    });
  };

  renderMobileTopBarMenu = () => {
    const active_business = dataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS);

    return (
      <div className="topbar mobile">
        {/* <Button type="link" onClick={this.toggleCollapsed}>
        </Button> */}

        <span
          className="menu"
          onClick={this.toggleCollapsed}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              this.toggleCollapsed();
            }
          }}
          role="button"
          tabIndex="0"
        >
          <MonnifyIcon type="menu" />
        </span>
        <Dropdown overlay={this.renderSwitchBussiness()} trigger={["click"]}>
          <Button style={{ marginLeft: 0 }}>
            <MonnifyIcon type="BRIEF_CASE" />
            <span
              style={{
                marginRight: 40,
                textOverflow: "ellipsis",
                width: 100,
                overflow: "hidden",
              }}
            >
              {active_business ? active_business.businessName : "Select Business"}
            </span>
            <MonnifyIcon type="ARROW_DOWN" />
          </Button>
        </Dropdown>
        <Badge count={0} className="notify">
          <MonnifyIcon type="notification-bell" />
        </Badge>
        <Dropdown overlay={this.renderUserMobileMenu()}>
          <div>
            <MonnifyIcon type="menu_three_dots" />
          </div>
        </Dropdown>
      </div>
    );
  };

  renderDestopTopBarMenu = () => {
    const active_business = dataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS);

    return (
      <div className="topbar desktop">
        <Dropdown overlay={this.renderSwitchBussiness()} trigger={["click"]}>
          <Button style={{ marginLeft: 0 }}>
            <MonnifyIcon type="BRIEF_CASE" />{" "}
            <span style={{ marginRight: 40 }}>
              {active_business ? active_business.businessName : "Select Business"}
            </span>{" "}
            <MonnifyIcon type="ARROW_DOWN" />
          </Button>
        </Dropdown>
        {this.props.kycStatusCompleted && (
          <div
            style={{
              marginLeft: this.props.showEnvironmentSwitch ? "auto" : 0,
            }}
          >
            {this.props.showEnvironmentSwitch ? (
              <EnvironmentSwitch />
            ) : (
              <ActivationButton
                style={{
                  padding: "0.8rem 1rem",
                }}
                className="outline-primary-btn"
                onClick={this.props.moveToOnboard}
              >
                Activate Your Business
                <MonnifyIcon style={{ marginLeft: 10 }} type="ARROW_RIGHT" />
              </ActivationButton>
            )}
          </div>
        )}

        <Media
          query="(max-width: 600px)"
          render={() => (
            <Dropdown overlay={this.renderUserMobileMenu()}>
              <DropdownAvatar>
                <Icon type="user" style={{ fontSize: "20px", color: "#ddd" }} />
              </DropdownAvatar>
            </Dropdown>
          )}
        />

        <div
          className="topbar-menu"
          style={{
            marginLeft: this.props.showEnvironmentSwitch ? 0 : "auto",
          }}
        >
          <div className="user-menu">
            <Badge count={0} className="notify">
              <MonnifyIcon type="notification-bell" />
            </Badge>

            <Dropdown overlay={this.renderProfileMenu()}>
              <DropdownAvatar className="full-dropdown">
                <MonnifyIcon type="profile" />
              </DropdownAvatar>
            </Dropdown>
            <Tooltip title="Logout">
              <Button className="logout-button" onClick={this.logout} type="link" block>
                <MonnifyIcon type="logout" />
                {/* Logout */}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { collapsed } = this.props;
    return (
      <Media query="(max-width: 600px)">
        {(matches) =>
          matches ? (
            <div className={`topbar-wrapper mobile ${collapsed ? "collapsed-sider" : ""}`}>
              {this.renderMobileTopBarMenu()}
            </div>
          ) : (
            <div className={`topbar-wrapper ${collapsed ? "collapsed-sider" : ""}`}>
              {this.renderDestopTopBarMenu()}
            </div>
          )
        }
      </Media>
    );
  }
}

const ActivationButton = styled.div`
  background: linear-gradient(173.58deg, #f0aa22 -8.91%, #f05822 99.52%);
  border-radius: 5px;
  color: #fff;
  padding: 1.5em 2em;
  margin-right: 30px;
  line-height: 0;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

const DropdownAvatar = styled.div`
  border-radius: 100px;
  height: 32px;
  width: 32px;
  background: rgba(0, 184, 194, 0.5);
  position: absolute;
  right: 20px;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

TopBar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  onboardingData: PropTypes.shape({
    merchantKycStatus: PropTypes.string,
    kycStatusCompleted: PropTypes.bool,
  }).isRequired,
  showEnvironmentSwitch: PropTypes.bool.isRequired,
  kycStatusCompleted: PropTypes.bool.isRequired,
  toggleShowSelectBusinessModal: PropTypes.func.isRequired,
  moveToAddBusinessPage: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  moveToOnboard: PropTypes.func.isRequired,
  setLayoutStateAction: PropTypes.func.isRequired,
};

export default TopBar;
