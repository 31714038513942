import React from "react";
import { connect } from "react-redux";
import { initLogin } from "../../../common/actions/auth";
import MonnifyIcon from "../../../common/icons";
import "./style.scss";
import PropTypes from "prop-types";

@connect(null, { initLogin })
class BasePage extends React.Component {
  static defaultProps = {
    loginRequired: false,
    containerExtraClass: "out-app-page",
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.initLogin();
  }

  render() {
    return (
      <main className="monnify-no-auth-page main-login main-login--fullscreen">
        <div className="container">
          <div className="content-wrap">
            <div className="main-content">
              <img className="logo-image" src="images/logo-white.svg" alt="" />
              <div className={`form-section ${this.props.className}`}>{this.props.children}</div>

              <div className="footer-links">
                <div className="links">
                  <a href="https://monnify.com">
                    <MonnifyIcon type="MONNIFY_BLUE_ICON" /> Monnify Website
                  </a>
                  <a href="https://support.monnify.com">
                    <MonnifyIcon type="QUESTION_MARK_BLUE" /> Support Portal
                  </a>
                  <a href="https://developers.monnify.com">
                    <MonnifyIcon type="DEVELOPER_BLUE" /> Developer Documentation
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

BasePage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  initLogin: PropTypes.func,
};

export default BasePage;
