import React from "react";
import Page from "../../../common/components/LayoutComponents/Page";
import BasePage from "../BasePage";
import { Helmet } from "react-helmet-async";
import LoginForm from "./LoginForm";
import mixpanel from "mixpanel-browser";

class LoginPage extends React.Component {
  static defaultProps = {
    loginRequired: false,
    containerExtraClass: "out-app-page",
  };

  componentDidMount() {
    mixpanel.reset();
  }

  render() {
    const { ...props } = this.props;

    return (
      <Page {...props}>
        <Helmet title="Login" />
        <BasePage className="login">
          <LoginForm />
        </BasePage>
      </Page>
    );
  }
}

export default LoginPage;
